import React, { useState } from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useIntl } from "react-intl";
import { SpeedDialAction } from "@material-ui/lab";
import RoomWrapper from "@vonage/video-express/dist/mp/room";
import { useHistory } from "react-router-dom";
import { useStyles } from "./LeaveCallSpeedDialAction.styles";
import { messages } from "./LeaveCallSpeedDialAction.messages";
import { RoutePath } from "../../../../../../Components/Routes/RoutePath";
import AlertDialog from "../../../../../../Components/Dialogs/AlertDialog/AlertDialog";
import {
  Key,
  removeLocalStorageItem,
} from "../../../../../../Utils/LocalStorage";

interface Props {
  open: boolean;
  delay: number;
  room: RoomWrapper;
  meetingId: string;
}
export default function LeaveCallSpeedDialAction(props: Props) {
  const { open, delay, room, meetingId } = props;
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const redirectToBookingDetails = () => {
    history.push({
      pathname: RoutePath.BOOKING_HISTORY,
      search: `?meetingId=${meetingId}`,
      state: { loadFromMeetingSession: true },
    });
  };

  const handleLeave = () => {
    room.leave().then(() => {
      removeLocalStorageItem(Key.OPENTOK_CLIENT_ID);
      redirectToBookingDetails();
    });
  };

  return (
    <>
      <AlertDialog
        open={isDialogOpen}
        title={intl.formatMessage(messages.dialogTitle)}
        submitLabel={intl.formatMessage(messages.dialogSubmitButton)}
        cancelLabel={intl.formatMessage(messages.dialogCancelButton)}
        onSubmit={handleLeave}
        onClose={() => setIsDialogOpen(false)}
      />
      <SpeedDialAction
        key="leave-call"
        icon={
          <div className={classes.container}>
            <ExitToAppIcon className={classes.icon} />
            {intl.formatMessage(messages.endCall)}
          </div>
        }
        onClick={() => setIsDialogOpen(true)}
        open={open}
        delay={delay}
        FabProps={{
          variant: "extended",
        }}
        classes={{ fab: classes.fab }}
        TooltipClasses={{ tooltip: classes.hidden }}
        className={classes.fabContainer}
      />
    </>
  );
}
