import * as Yup from "yup";

// text string is used as a key to fetch intl message
const UserValidationSchema = (usesPersonNumber: boolean) => {
  if (!usesPersonNumber) {
    return Yup.object().shape(
      {
        firstName: Yup.string()
          .max(50, "firstNameTooLong")
          .required("firstNameRequired"),
        lastName: Yup.string()
          .max(50, "lastNameTooLong")
          .required("lastNameRequired"),
        mobilePhoneNumber: Yup.string().required("phoneNumberRequired"),
        email: Yup.string().email("emailInvalid"),
      },
      [
        ["email", "personNumber"],
        ["mobilePhoneNumber", "email"],
        ["mobilePhoneNumber", "personNumber"],
      ]
    );
  }

  return Yup.object().shape(
    {
      personNumber: Yup.string()
        .required("personNumberRequired")
        .min(12, "personNumberInvalid")
        .matches(/^[0-9-]*$/, "personNumberInvalid")
        .matches(/^(19|20)/, "personNumberInvalid")
        .test("containsDash", "personNumberRequired", (value) => {
          if (value) {
            if (value.includes("-")) {
              return (
                value.indexOf("-") === value.length - 5 &&
                value.indexOf("-") === 8 &&
                value.match(/-/g)?.length === 1
              );
            }
            return value.length === 12;
          }
          return true;
        }),
      firstName: Yup.string()
        .max(50, "firstNameTooLong")
        .required("firstNameRequired"),
      lastName: Yup.string()
        .max(50, "lastNameTooLong")
        .required("lastNameRequired"),
      email: Yup.string().email("emailInvalid"),
    },
    [
      ["email", "personNumber"],
      ["mobilePhoneNumber", "email"],
      ["mobilePhoneNumber", "personNumber"],
    ]
  );
};

export default UserValidationSchema;
