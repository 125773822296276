import React from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Avatar from "@material-ui/core/Avatar";
import { useSnackbar } from "notistack";
import { useStyles } from "./FileCard.style";
import CardHeading from "../../../../Components/CardHeading/CardHeading";
import { FileMetadata } from "../../../../generated/file-api";
import FileActionButton from "../../../../Components/Buttons/FileActionButton/FileActionButton";
import { displayUser } from "../../../../Utils/User";
import { fileService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import {
  useCustomerFeatures,
  useCustomerId,
} from "../../../../Providers/CustomerProvider/CustomerProvider";
import { messages } from "./FileCard.messages";
import { hasFeatureEnabled } from "../../../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";

interface Props {
  files?: FileMetadata[];
}

const FileCard = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { files } = props;
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const customerFeatures = useCustomerFeatures();
  const doFormatPersonNumber = hasFeatureEnabled(
    CustomerFeatureType.UsesPersonNumber,
    customerFeatures
  );

  const openInNewTab = (file: FileMetadata) => {
    // Trigger window.open() outside async method to be able to make it work for safari on iphone
    let windowRef: Window | null = null;
    windowRef = window.open();

    if (customerId) {
      fileService()
        .downloadFileRaw({
          customerId,
          fileId: file.fileId,
        })
        .then((res) => Promise.resolve(res.raw.blob()))
        .then((blob) => {
          if (windowRef !== null) {
            // @ts-ignore
            windowRef.location = URL.createObjectURL(blob);
          }
        })
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        );
    }
  };

  const mapfiles = files?.map((file) => {
    return (
      <>
        <ListItem
          button
          className={classes.root}
          onClick={() => openInNewTab(file)}
        >
          <ListItemAvatar>
            <Avatar>
              <InsertDriveFileIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={file.fileName}
            secondary={displayUser(doFormatPersonNumber, file.uploadedBy)}
          />
          <ListItemSecondaryAction>
            <FileActionButton
              fileId={file.fileId}
              fileName={file.fileName}
              uploadedBy={file.uploadedBy.id}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </>
    );
  });

  if (!files || files.length < 1) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <CardHeading noMarginBottom>
        {intl.formatMessage(messages.title).toUpperCase()}
      </CardHeading>
      <List className={classes.list}>{mapfiles}</List>
    </Paper>
  );
};

export default FileCard;
