import { defineMessages } from "react-intl";

export const messages = defineMessages({
  generalTabLabel: {
    id: "accountview.tab.general.label",
    defaultMessage: "General",
    description: "Label for tab general",
  },
  usernameAndPasswordTabLabel: {
    id: "accountview.tab.usernameAndPassword.label",
    defaultMessage: "Username and Password",
    description: "Label for tab username and password",
  },
  consentTabLabel: {
    id: "accountview.tab.consent.label",
    defaultMessage: "Consent",
    description: "Label for tab consent",
  },
  privacyPolicyTabLabel: {
    id: "accountview.tab.privacyPolicy.label",
    defaultMessage: "Privacy policy",
    description: "Label for tab privacy policy",
  },
});
