import { defineMessages } from "react-intl";

export const messages = defineMessages({
  titleLabel: {
    id: "meetingForm.title.label",
    defaultMessage: "Meeting title",
    description: "Label for field meeting title",
  },
  dateTimeLabel: {
    id: "meetingForm.datetime.label",
    defaultMessage: "Date and time",
    description: "Label for field date and time",
  },
  calendarTimeLabel: {
    id: "meetingForm.calendar.time",
    defaultMessage: "Time",
    description: "Label for time in calendar",
  },
  durationLabel: {
    id: "meetingForm.duration.label",
    defaultMessage: "Duration",
    description: "Label for field duration",
  },
  participantsLabel: {
    id: "meetingForm.participants.label",
    defaultMessage: "Participants",
    description: "Label for field participants",
  },
  externalParticipantsLabel: {
    id: "meetingForm.externalParticipants.label",
    defaultMessage: "Externals",
    description: "Label for field externals",
  },
  staffParticipantsLabel: {
    id: "meetingForm.staffParticipants.label",
    defaultMessage: "Caregiver",
    description: "Label for field staff participants",
  },
  participantsHeader: {
    id: "meetingForm.participants.header",
    defaultMessage: "Participants",
    description: "Header for section participants",
  },
  createClientDialogTitle: {
    id: "scheduleMeeting.form.createClient.dialog.title",
    defaultMessage: "Create patient",
    description: "Title for create user dialog",
  },
  createExternalDialogTitle: {
    id: "scheduleMeeting.form.createExternal.dialog.title",
    defaultMessage: "Create external user",
    description: "Title for create user dialog",
  },
  createClientButtonLabel: {
    id: "scheduleMeeting.form.createClient.button.label",
    defaultMessage: "Add new patient",
    description: "Button label for create new patient",
  },
  createExternalButtonLabel: {
    id: "scheduleMeeting.form.createExternal.button.label",
    defaultMessage: "Add new external",
    description: "Button label for create new patient",
  },
});
