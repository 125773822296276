/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonNumberResponse
 */
export interface PersonNumberResponse {
    /**
     * The UUID of this user
     * @type {string}
     * @memberof PersonNumberResponse
     */
    id: string;
    /**
     * The person number of this user
     * @type {string}
     * @memberof PersonNumberResponse
     */
    personNumber?: string;
}

export function PersonNumberResponseFromJSON(json: any): PersonNumberResponse {
    return PersonNumberResponseFromJSONTyped(json, false);
}

export function PersonNumberResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonNumberResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'personNumber': !exists(json, 'personNumber') ? undefined : json['personNumber'],
    };
}

export function PersonNumberResponseToJSON(value?: PersonNumberResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'personNumber': value.personNumber,
    };
}


