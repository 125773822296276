import { defineMessages } from "react-intl";

export const messages = defineMessages({
  messages: {
    id: "feature.messages.label",
    defaultMessage: "Messages",
    description: "Feature label",
  },
  oneWayMessage: {
    id: "feature.oneWayMessage.label",
    defaultMessage: "One-way messages only",
    description: "Feature label",
  },
  inMeetingChat: {
    id: "feature.inMeetingChat.label",
    defaultMessage: "Chat function",
    description: "Feature label",
  },
  inMeetingNotes: {
    id: "feature.inMeetingNotes.label",
    defaultMessage: "Private notes",
    description: "Feature label",
  },
  inMeetingTodo: {
    id: "feature.inMeetingTodo.label",
    defaultMessage: "Todo list",
    description: "Feature label",
  },
  inMeetingFileShare: {
    id: "feature.inMeetingFileShare.label",
    defaultMessage: "File sharing",
    description: "Feature label",
  },
  usesCustomDomain: {
    id: "feature.usesCustomDomain.label",
    defaultMessage: "Use custom domain name",
    description: "Feature label",
  },
  usesMeetingConfirmation: {
    id: "feature.usesMeetingConfirmation.label",
    defaultMessage: "Patient/extern booking confirmation",
    description: "Feature label",
  },
  usesPersonNumber: {
    id: "feature.usesPersonNumber.label",
    defaultMessage: "Activate usage of person number",
    description: "Feature label",
  },
  usesHsaId: {
    id: "feature.usesHsaId.label",
    defaultMessage: "Show HSA-ID, caregiver",
    description: "Feature label",
  },
  notificationClientMeetingInvited: {
    id: "feature.notificationClientMeetingInvited.label",
    defaultMessage: "Notification for patient/external: New visit invitation",
    description: "Feature label",
  },
  notificationClientMeetingRescheduled: {
    id: "feature.notificationClientMeetingRescheduled.label",
    defaultMessage: "Notification for patient/external: Visit re-scheduled",
    description: "Feature label",
  },
  notificationClientMeetingCancelled: {
    id: "feature.notificationClientMeetingCancelled.label",
    defaultMessage: "Notification for patient/external: Visit cancelled",
    description: "Feature label",
  },
  notificationClientMeetingReminder: {
    id: "feature.notificationClientMeetingReminder.label",
    defaultMessage: "Notification for patient/external: Visit reminder",
    description: "Feature label",
  },
  notificationClientMessageReceived: {
    id: "feature.notificationClientMessageReceived.label",
    defaultMessage: "Notification for patient/external: New message",
    description: "Feature label",
  },
  notificationStaffMeetingInvited: {
    id: "feature.notificationStaffMeetingInvited.label",
    defaultMessage: "Notification for caregiver: New visit invitation",
    description: "Feature label",
  },
  notificationStaffMeetingRescheduled: {
    id: "feature.notificationStaffMeetingRescheduled.label",
    defaultMessage: "Notification for caregiver: Visit re-scheduled",
    description: "Feature label",
  },
  notificationStaffMeetingCancelled: {
    id: "feature.notificationStaffMeetingCancelled.label",
    defaultMessage: "Notification for caregiver: Visit cancelled",
    description: "Feature label",
  },
  notificationStaffMeetingReminder: {
    id: "feature.notificationStaffMeetingReminder.label",
    defaultMessage: "Notification for caregiver: Visit reminder",
    description: "Feature label",
  },
  notificationStaffMessageReceived: {
    id: "feature.notificationStaffMessageReceived.label",
    defaultMessage: "Notification for caregiver: New message",
    description: "Feature label",
  },
  notificationClientUploadedFile: {
    id: "feature.notificationClientUploadedFile.label",
    defaultMessage: "Notification for caregiver: New message",
    description: "Feature label",
  },
  notificationStaffUploadedFile: {
    id: "feature.notificationStaffUploadedFile.label",
    defaultMessage: "Notification for caregiver: New message",
    description: "Feature label",
  },
  inMeetingScreenShareClient: {
    id: "feature.inMeetingScreenShareClient.label",
    defaultMessage: "Screen sharing, patient/extern",
    description: "Feature label",
  },
  inMeetingScreenShareStaff: {
    id: "feature.inMeetingScreenShareStaff.label",
    defaultMessage: "Screen sharing, caregiver",
    description: "Feature label",
  },
  anonymizeNoneActiveClient: {
    id: "feature.anonymizeNoneActiveClient.label",
    defaultMessage: "Deletion of patient account (days)",
    description: "Feature label",
  },
  anonymizeNoneActiveStaff: {
    id: "feature.anonymizeNoneActiveStaff.label",
    defaultMessage: "Deletion of caregiver account (days)",
    description: "Feature label",
  },
  anonymizeNoneActiveExternal: {
    id: "feature.anonymizeNoneActiveExternal.label",
    defaultMessage: "Deletion of external account (days)",
    description: "Feature label",
  },
  anonymizeClosedUser: {
    id: "feature.anonymizeClosedUser.label",
    defaultMessage: "Deletion of users account (days)",
    description: "Feature label",
  },
  anonymizeOldTodos: {
    id: "feature.anonymizeOldTodos.label",
    defaultMessage: "Deletion of todo item (days)",
    description: "Feature label",
  },
  anonymizeOldFiles: {
    id: "feature.anonymizeOldFiles.label",
    defaultMessage: "Deletion of shared files (days)",
    description: "Feature label",
  },
  anonymizeOldNotes: {
    id: "feature.anonymizeOldNotes.label",
    defaultMessage: "Deletion of notes (days)",
    description: "Feature label",
  },
  anonymizeOldMeetings: {
    id: "feature.anonymizeOldMeetings.label",
    defaultMessage: "Deletion of visits (days)",
    description: "Feature label",
  },
  anonymizeOldMessages: {
    id: "feature.anonymizeOldMessages.label",
    defaultMessage: "Deletion of messages (days)",
    description: "Feature label",
  },
  anonymizeOldChatMessages: {
    id: "feature.anonymizeOldChatMessages.label",
    defaultMessage: "Deletion of chat messages (days)",
    description: "Feature label",
  },
  anonymizeExpiredInvites: {
    id: "feature.anonymizeExpiredInvites.label",
    defaultMessage: "Deletion of invites (days)",
    description: "Feature label",
  },
  takeCareSync: {
    id: "feature.takeCareSync.label",
    defaultMessage: "Show TC sync button",
    description: "Feature label",
  },
  webdocUpdateBookingButton: {
    id: "feature.webdocUpdateBookingButton.label",
    defaultMessage:
      "Webdoc update booking button (Only for patients synced by Webdoc)",
    description: "Feature label",
  },
  allowExternalGroupBookings: {
    id: "feature.allowExternalGroupBookings.label",
    defaultMessage: "Group visits from external systems",
    description: "Feature label",
  },
  externalUsers: {
    id: "feature.externalUsers.label",
    defaultMessage: "External users",
    description: "Feature label",
  },
  inviteClient: {
    id: "feature.inviteClient.label",
    defaultMessage: "Invite function, patient",
    description: "Feature label",
  },
  inviteStaff: {
    id: "feature.inviteStaff.label",
    defaultMessage: "Invite function, caregiver",
    description: "Feature label",
  },
  inviteExternal: {
    id: "feature.inviteExternal.label",
    defaultMessage: "Invite function, external user",
    description: "Feature label",
  },
  bankIdLoginClient: {
    id: "feature.bankIdLoginClient.label",
    defaultMessage: "BankID for client",
    description: "Feature label",
  },
  bankIdLoginStaff: {
    id: "feature.bankIdLoginStaff.label",
    defaultMessage: "BankID for staff",
    description: "Feature label",
  },
  bankIdLoginExternal: {
    id: "feature.bankIdLoginExternal.label",
    defaultMessage: "BankID for external",
    description: "Feature label",
  },
  sithsLogin: {
    id: "feature.sithsLogin.label",
    defaultMessage: "SITHS card for caregiver",
    description: "Feature label",
  },
  usernameLoginClient: {
    id: "feature.usernameLoginClient.label",
    defaultMessage: "Username + password, Client",
    description: "Feature label",
  },
  usernameLoginStaff: {
    id: "feature.usernameLoginStaff.label",
    defaultMessage: "Username + password, caregiver",
    description: "Feature label",
  },
  usernameLoginExternal: {
    id: "feature.usernameLoginExternal.label",
    defaultMessage: "Username + password, External",
    description: "Feature label",
  },
  updateClientsViaContacts: {
    id: "feature.updateClientsViaContacts.label",
    defaultMessage: "Allow caregivers to update patients via contacts",
    description: "Feature label",
  },
  updateExternalsViaContacts: {
    id: "feature.updateExternalsViaContacts.label",
    defaultMessage: "Allow caregivers to update externals via contacts",
    description: "Feature label",
  },
  notificationWebdoc: {
    id: "feature.notificationWebdoc.label",
    defaultMessage: "Notifications, visits from Webdoc",
    description: "Feature label",
  },
  notificationTakecare: {
    id: "feature.notificationTakecare.label",
    defaultMessage: "Notifications, visits from Takecare",
    description: "Feature label",
  },
  notificationCgmj4: {
    id: "feature.notificationCgmj4.label",
    defaultMessage: "Notifications, visits from CGMJ4",
    description: "Feature label",
  },
  adminBookingList: {
    id: "feature.adminBookingList.label",
    defaultMessage: "List future bookings by default for admins",
    description: "Feature label",
  },
  autoCreatePasswordClient: {
    id: "feature.autoCreatePasswordClient.label",
    defaultMessage: "Auto create password for client",
    description: "Feature label",
  },
  autoCreatePasswordStaff: {
    id: "feature.autoCreatePasswordStaff.label",
    defaultMessage: "Auto create password for staff",
    description: "Feature label",
  },
  autoCreatePasswordExternal: {
    id: "feature.autoCreatePasswordExternal.label",
    defaultMessage: "Auto create password for external",
    description: "Feature label",
  },
  debugView: {
    id: "feature.debugView.label",
    defaultMessage: "Show debug view",
    description: "Feature label",
  },
  contentWelcomeTextTitle: {
    id: "setting.contentWelcomeTextTitle.label",
    defaultMessage: "Welcome text Title",
    description: "Welcome text title label",
  },
  contentWelcomeText: {
    id: "setting.contentWelcomeText.label",
    defaultMessage: "Welcome Text",
    description: "Welcome text label",
  },
  contentNoticeText: {
    id: "setting.contentNoticeText.label",
    defaultMessage: "Notice Text",
    description: "Notice text label",
  },
  contentLinkButton: {
    id: "setting.contentLinkButton.label",
    defaultMessage: "Link button",
    description: "Link button label",
  },
  contentFeedbackButton: {
    id: "setting.contentFeedbackButton.label",
    defaultMessage: "Feedback button",
    description: "Feedback button label",
  },
  contentTechnicalSupportPhone: {
    id: "setting.contentTechnicalSupportPhone.label",
    defaultMessage: "Technical support phone number",
    description: "Technical support phone number",
  },
  contentTechnicalSupportEmail: {
    id: "setting.contentTechnicalSupportEmail.label",
    defaultMessage: "Technical support email",
    description: "Technical support email",
  },
  contentTechnicalSupportOpeningHours: {
    id: "setting.contentTechnicalSupportOpeningHours.label",
    defaultMessage: "Technical support opening hours",
    description: "Technical support opening hours",
  },
  contentContactDetailsPhone: {
    id: "setting.contentContactDetailsPhone.label",
    defaultMessage: "Contact details phone",
    description: "Contact details phone number",
  },
  contentContactDetailsEmail: {
    id: "setting.contentContactDetailsEmail.label",
    defaultMessage: "Contact details email",
    description: "Contact details email",
  },
  contentContactDetailsOpeningHours: {
    id: "setting.contentContactDetailsOpeningHours.label",
    defaultMessage: "Contact details, opening hours",
    description: "Contact details opening hours",
  },
});
