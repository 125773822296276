import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { Bookmark, LiveHelp } from "@material-ui/icons";
import VideocamIcon from "@material-ui/icons/Videocam";

import { Link } from "react-router-dom";
import CardHeading from "../../../../Components/CardHeading/CardHeading";
import { useStyles } from "./QuickMenuCard.style";
import { messages } from "./QuickMenuCard.messages";
import {
  UserAuthorityType,
  UserRoleType,
} from "../../../../generated/user-api";
import PermissionController from "../../../../Components/PermissionController/PermissionController";
import LatestVisit from "../LatestVisit/LatestVisit";
import { RoutePath } from "../../../../Components/Routes/RoutePath";
import CreateMeetingTemplateQuickCardButton from "../../../../Components/Buttons/CreateMeetingTemplateQuickCardButton/CreateMeetingQuickCardTemplateButton";
import { Market } from "../../../../generated/customersettings-api";
import MarketController from "../../../../Components/MarketController/MarketController";

interface ListItemConfig {
  id: string;
  title: string;
  icon?: JSX.Element;
  path?: string;
  newTab?: boolean;
  allowedRoles: UserRoleType[];
  requiredAuthority?: UserAuthorityType[];
  allowedMarkets: Market[];
  Children?: JSX.Element;
}

const QuickMenuCard = () => {
  const intl = useIntl();
  const classes = useStyles();

  const QUICK_MENU: ListItemConfig[] = [
    {
      id: "test-video",
      title: intl.formatMessage(messages.testVideo),
      path: RoutePath.TEST_VIDEO,
      newTab: false,
      icon: <VideocamIcon />,
      allowedRoles: [
        UserRoleType.Client,
        UserRoleType.Staff,
        UserRoleType.External,
      ],
      allowedMarkets: [Market.Sweden, Market.Finland, Market.Morocco],
    },
    {
      id: "create-meeting-template",
      title: intl.formatMessage(messages.CreateMeetingTemplate),
      icon: <Bookmark />,
      allowedRoles: [UserRoleType.Staff],
      requiredAuthority: [
        UserAuthorityType.BookMeeting,
        UserAuthorityType.AdminSite,
      ],
      allowedMarkets: [Market.Sweden, Market.Finland, Market.Morocco],
      Children: (
        <CreateMeetingTemplateQuickCardButton
          title={intl.formatMessage(messages.CreateMeetingTemplate)}
          icon={<Bookmark />}
        />
      ),
    },
    {
      id: "user-guide-patient",
      title: intl.formatMessage(messages.userGuide),
      path: "https://careplatform.se/anvandarguider/patient/",
      newTab: true,
      icon: <LiveHelp />,
      allowedRoles: [UserRoleType.Client],
      allowedMarkets: [Market.Sweden, Market.Finland],
    },
    {
      id: "user-guide",
      title: intl.formatMessage(messages.userGuide),
      path: "https://careplatform.se/anvandarguider/",
      newTab: true,
      icon: <LiveHelp />,
      allowedRoles: [UserRoleType.Staff],
      allowedMarkets: [Market.Sweden, Market.Finland],
    },
  ];

  return (
    <Paper className={classes.root}>
      <div>
        <CardHeading>
          {intl.formatMessage(messages.quickMenuTitle).toUpperCase()}
        </CardHeading>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.list}
        >
          {QUICK_MENU.map((config) => (
            <MarketController
              key={config.id}
              allowedMarkets={config.allowedMarkets}
            >
              <PermissionController
                key={config.id}
                allowedRoles={config.allowedRoles}
                atLeastOneOfAuthoritiesStaff={config.requiredAuthority}
                atLeastOneOfAuthoritiesClient={config.requiredAuthority}
                atLeastOneOfAuthoritiesExternal={config.requiredAuthority}
              >
                {config.path ? (
                  <ListItem
                    key={config.id}
                    button
                    divider
                    className={classes.hover}
                    component={Link}
                    to={{ pathname: config.path }}
                    target={config.newTab ? "_blank" : undefined}
                  >
                    <ListItemIcon>{config.icon}</ListItemIcon>
                    <ListItemText
                      primary={config.title}
                      primaryTypographyProps={{ variant: "button" }}
                    />
                  </ListItem>
                ) : (
                  config.Children
                )}
              </PermissionController>
            </MarketController>
          ))}
        </List>
      </div>
      <LatestVisit />
    </Paper>
  );
};

export default QuickMenuCard;
