import React from "react";
import { useFormik } from "formik";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { useIntl } from "react-intl";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useSnackbar } from "notistack";
import { messages } from "./UpdatePasswordDialog.messages";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { userService } from "../../../Providers/ServiceProvider/ServiceProvider";
import UpdatePasswordFields, {
  UpdatePasswordFormikValues,
} from "../../Fields/UpdatePasswordFields/UpdatePasswordFields";
import UpdatePasswordValidationSchema from "../../Fields/UpdatePasswordFields/Components/Validation/UpdatePasswordValidationSchema";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onUpdated?: () => void;
}

const UpdatePasswordDialog = (props: Props) => {
  const { isOpen, onClose, onUpdated } = props;
  const intl = useIntl();
  const theme = useTheme();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const initialValues = {
    newPassword: "",
    currentPassword: "",
  } as UpdatePasswordFormikValues;

  const formik = useFormik({
    initialValues,
    validationSchema: UpdatePasswordValidationSchema,
    onSubmit: (values) => {
      if (customerId && authenticatedUser?.user) {
        userService()
          .updatePassword({
            customerId,
            updatePasswordRequest: {
              newPassword: values.newPassword,
              currentPassword: values.currentPassword,
            },
          })
          .then(() => {
            enqueueSnackbar(intl.formatMessage(messages.success), {
              variant: "success",
            });
          })
          .then(onUpdated)
          .catch(() =>
            enqueueSnackbar(intl.formatMessage(messages.generalError), {
              variant: "error",
            })
          )
          .finally(onClose);
      }
    },
  });

  return (
    <Dialog
      onClick={(e) => e.stopPropagation()}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="Update-password-dialog"
    >
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage(messages.dialogTitle)}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <UpdatePasswordFields formik={formik} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" color="primary">
            {intl.formatMessage(messages.submitButtonLabel)}
          </Button>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage(messages.cancelButtonLabel)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdatePasswordDialog;
