/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * User preferred locale
 * @export
 * @enum {string}
 */
export enum UserLocaleType {
    Swedish = 'SWEDISH',
    English = 'ENGLISH',
    French = 'FRENCH'
}

export function UserLocaleTypeFromJSON(json: any): UserLocaleType {
    return UserLocaleTypeFromJSONTyped(json, false);
}

export function UserLocaleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLocaleType {
    return json as UserLocaleType;
}

export function UserLocaleTypeToJSON(value?: UserLocaleType | null): any {
    return value as any;
}

