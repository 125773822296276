import { defineMessages } from "react-intl";

export const messages = defineMessages({
  kindTypesHeader: {
    id: "dialog.meetingTemplateForm.kindTypesHeader",
    defaultMessage: "Booking templates",
    description: "Heading for section booking offer in create meeting",
  },
  titleLabel: {
    id: "meetingTemplateForm.title.label",
    defaultMessage: "Meeting title",
    description: "Label for field meeting title",
  },
  durationLabel: {
    id: "meetingTemplateForm.duration.label",
    defaultMessage: "Duration",
    description: "Label for field duration",
  },
  personalTypeLabel: {
    id: "meetingTemplateForm.personal.label",
    defaultMessage: "Personal",
    description: "Label for personal Type Label",
  },
  publicTypeLabel: {
    id: "meetingTemplateForm.public.label",
    defaultMessage: "Public",
    description: "Label for public Type Label",
  },
});
