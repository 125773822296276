import * as Yup from "yup";

// text string is used as a key to fetch intl message
const UpdateUsernameValidationSchema = () => {
  return Yup.object().shape({
    newUsername: Yup.string()
      .min(3, "usernameTooShort")
      .max(150, "usernameTooLong")
      .required("usernameRequired"),
    password: Yup.string().required("passwordRequired"),
  });
};

export default UpdateUsernameValidationSchema;
