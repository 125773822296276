import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import TextField from "../../../../Fields/TextField/TextField";
import {
  useCustomerFeatures,
  useCustomerId,
} from "../../../../../Providers/CustomerProvider/CustomerProvider";
import { messages } from "./SearchField.messages";
import { useAuthenticatedUser } from "../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  validEmail,
  validPersonNumber,
  validPhoneNumber,
} from "../../../../../Utils/RegExp";
import { hasFeatureEnabled } from "../../../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../../generated/customersettings-api";

export enum Variant {
  INVITE,
  CREATE,
  INVITE_OR_CREATE,
}

interface InitialValues {
  searchQuery: string;
}

export interface Query {
  email: string;
  personNumber: string;
}

interface Props {
  onSearch: (query?: Query) => void;
  variant: Variant;
}

const SearchField = (props: Props) => {
  const intl = useIntl();
  const { onSearch, variant } = props;
  const customerId = useCustomerId();
  const customerFeatures = useCustomerFeatures();
  const [authenticatedUser] = useAuthenticatedUser();
  const usesPersonNumber = hasFeatureEnabled(
    CustomerFeatureType.UsesPersonNumber,
    customerFeatures
  );

  const initialValues: InitialValues = {
    searchQuery: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {},
  });

  const handleSearch = (currentQuery: string) => {
    const isValidEmail = validEmail.test(currentQuery);
    const isValidPhoneNumber = validPhoneNumber.test(currentQuery);
    const isValidPersonNumber = validPersonNumber.test(
      currentQuery.replace("-", "")
    );

    if (customerId && authenticatedUser.user) {
      let personNumberValue;
      if (usesPersonNumber) {
        personNumberValue = isValidPersonNumber
          ? currentQuery.replace("-", "")
          : undefined;
      } else {
        personNumberValue = isValidPhoneNumber ? currentQuery : undefined;
      }

      const query = {
        personNumber: personNumberValue,
        email: isValidEmail ? currentQuery : undefined,
      } as Query;

      onSearch(query);
    }
  };

  const isSearchable = (query?: string): boolean => {
    if (query) {
      switch (variant) {
        case Variant.CREATE:
          return usesPersonNumber
            ? validPersonNumber.test(query.replace("-", ""))
            : validPhoneNumber.test(query);
        case Variant.INVITE:
          return validEmail.test(query);
        case Variant.INVITE_OR_CREATE:
          return (
            validEmail.test(query) ||
            validPersonNumber.test(query.replace("-", ""))
          );
        default:
          return false;
      }
    }
    return false;
  };

  const getPlaceholder = (): string => {
    switch (variant) {
      case Variant.CREATE:
        return usesPersonNumber ? "YYYYMMDDNNNN" : "+46123456789";
      case Variant.INVITE:
        return "address@example.se";
      case Variant.INVITE_OR_CREATE:
        return "YYYYMMDDNNNN or address@example.se";
      default:
        return "";
    }
  };

  const getLabel = (): string => {
    switch (variant) {
      case Variant.CREATE:
        return intl.formatMessage(
          usesPersonNumber
            ? messages.searchCreatePersonNumberLabel
            : messages.searchCreatePhoneLabel
        );
      case Variant.INVITE:
        return intl.formatMessage(messages.searchInviteLabel);
      case Variant.INVITE_OR_CREATE:
        return intl.formatMessage(
          usesPersonNumber
            ? messages.searchCreateOrInvitePersonalNumberOrEmailLabel
            : messages.searchCreateOrInvitePhoneOrEmailLabel
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    if (isSearchable(formik.values.searchQuery)) {
      handleSearch(formik.values.searchQuery);
    } else {
      onSearch(undefined);
    }
  }, [formik.values.searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TextField
        id="searchQuery"
        formik={formik}
        placeholder={getPlaceholder()}
        label={getLabel()}
      />
    </>
  );
};

export default SearchField;
