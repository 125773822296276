import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { useIntl } from "react-intl";
import { Alert, Grid, Typography } from "@mui/material";
import { messages } from "./UserNamePassword.messages";
import { UserResponse } from "../../../../generated/user-api";
import { useStyles } from "./UserNamePassword.style";
import UpdateUsernameDialog from "../../../../Components/Dialogs/UpdateUsernameDialog/UpdateUsernameDialog";
import UpdatePasswordDialog from "../../../../Components/Dialogs/UpdatePasswordDialog/UpdatePasswordDialog";

interface Props {
  user: UserResponse;
  onUpdated?: () => void;
}

const UserNamePassword = (props: Props) => {
  const { user, onUpdated } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [isUsernameDialogOpen, setIsUsernameDialogOpen] = useState(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);

  if (!user.hasActivatedPassword) {
    return (
      <>
        <Alert severity="info" variant="standard">
          {intl.formatMessage(messages.alertPasswordLoginNotActivated)}
        </Alert>
      </>
    );
  }

  return (
    <>
      <UpdateUsernameDialog
        userId={user.id}
        onUpdated={onUpdated}
        isOpen={isUsernameDialogOpen}
        onClose={() => setIsUsernameDialogOpen(false)}
      />
      <UpdatePasswordDialog
        onUpdated={onUpdated}
        isOpen={isPasswordDialogOpen}
        onClose={() => setIsPasswordDialogOpen(false)}
      />
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {intl.formatMessage(messages.updateUsernameTitle)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            disabled
            value={user.username}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => setIsUsernameDialogOpen(true)}
          >
            {intl.formatMessage(messages.updateUsernameButton)}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            {intl.formatMessage(messages.updatePasswordTitle)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            disabled
            type="password"
            value={12345612890}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => setIsPasswordDialogOpen(true)}
          >
            {intl.formatMessage(messages.updatePasswordButton)}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UserNamePassword;
