import React from "react";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Typography } from "@mui/material";
import { useStyles } from "./ContactListItemAvatar.style";
import ProfilePicture from "../../../ProfilePicture/ProfilePicture";
import { UserRoleType } from "../../../../generated/contact-api";
import { Contact } from "../../../../Models/Contact";
import { displayUser, formatPersonNumber } from "../../../../Utils/User";
import { useCustomerFeatures } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { hasFeatureEnabled } from "../../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";

interface Props {
  contact: Contact;
}

const ContactListItemAvatar = (props: Props) => {
  const classes = useStyles();
  const { contact } = props;
  const { firstName, lastName, workTitle, userRole, personNumber } = contact;
  const customerFeatures = useCustomerFeatures();
  const doFormatPersonNumber = hasFeatureEnabled(
    CustomerFeatureType.UsesPersonNumber,
    customerFeatures
  );

  return (
    <div className={classes.root}>
      <ListItemAvatar>
        <ProfilePicture firstName={firstName} lastName={lastName} />
      </ListItemAvatar>
      <div className={classes.container}>
        <Typography variant="h6" color="textPrimary">
          {displayUser(doFormatPersonNumber, contact, true)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {userRole !== UserRoleType.Client
            ? workTitle
            : formatPersonNumber(doFormatPersonNumber, personNumber)}
        </Typography>
      </div>
    </div>
  );
};

export default ContactListItemAvatar;
