const ALLOWED_FILE_CONTENT_TYPE_VIDEO = [
  "video/mp4",
  "video/x-msvideo",
  "video/quicktime",
  "video/x-matroska",
  "video/webm",
];

const ALLOWED_FILE_CONTENT_TYPE_DOCUMENT = [
  "application/pdf",
  "text/plain",
  "text/csv",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
];

const ALLOWED_FILE_CONTENT_TYPE_AUDIO = [
  "audio/mpeg",
  "audio/wav",
  "audio/ogg",
  "audio/aac",
  "audio/flac",
];

const ALLOWED_FILE_CONTENT_TYPE_IMAGE = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/tiff",
  "image/svg+xml",
];

const ALLOWED_FILE_SUFFIX_VIDEO = [
  ".mp4",
  ".mov",
  ".avi",
  ".mkv",
  ".webm",
  ".flv",
  ".wmv",
  ".mpeg",
  ".mpg",
];

const ALLOWED_FILE_SUFFIX_AUDIO = [
  ".mp3",
  ".wav",
  ".flac",
  ".aac",
  ".ogg",
  ".m4a",
  ".wma",
  ".opus",
];

const ALLOWED_FILE_SUFFIX_DOCUMENT = [
  ".txt",
  ".csv",
  ".json",
  ".xml",
  ".md",
  ".rtf",
  ".pdf",
  ".doc",
  ".docx",
  ".dotx",
  ".odt",
  ".xls",
  ".xlsx",
  ".xltx",
  ".ods",
  ".ppt",
  ".pptx",
  ".potx",
  ".odp",
];

const ALLOWED_FILE_SUFFIX_IMAGE = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".bmp",
  ".tiff",
  ".webp",
  ".svg",
  ".ico",
  ".heic",
  ".heif",
];

export const ALLOWED_FILE_SUFFIX = [
  ...ALLOWED_FILE_SUFFIX_IMAGE,
  ...ALLOWED_FILE_SUFFIX_VIDEO,
  ...ALLOWED_FILE_SUFFIX_AUDIO,
  ...ALLOWED_FILE_SUFFIX_DOCUMENT,
];

export const ALLOWED_FILE_CONTENT_TYPE = [
  ...ALLOWED_FILE_CONTENT_TYPE_IMAGE,
  ...ALLOWED_FILE_CONTENT_TYPE_VIDEO,
  ...ALLOWED_FILE_CONTENT_TYPE_AUDIO,
  ...ALLOWED_FILE_CONTENT_TYPE_DOCUMENT,
];
