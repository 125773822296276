import React, { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import {
  useCustomerContent,
  useCustomerId,
} from "../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  MeetingResponse,
  MeetingStatusType,
  SortOrderType,
} from "../../generated/meeting-api";
import { RedirectToErrorPage } from "../ErrorPage/ErrorPage";
import { useStyles } from "./BookingDetails.style";
import ParticipantCard, {
  Variant,
} from "./Components/ParticipantCard/ParticipantCard";
import TodoCard from "../../Components/TodoCard/TodoCard";
import SharedFilesCard from "../../Components/SharedFilesCard/SharedFilesCard";
import PrivateNotesCard from "../../Components/PrivateNotesCard/PrivateNotesCard";
import ChatCard from "../../Components/ChatCard/ChatCard";
import BookingsListCard from "./Components/BookingsListCard/BookingsListCard";
import { Pagination } from "../../generated/user-api";
import { useSpinner } from "../../Components/Spinner/Spinner";
import { messages } from "./BookingsDetails.messages";
import {
  contactService,
  meetingService,
} from "../../Providers/ServiceProvider/ServiceProvider";
import WebSocketSession from "../../WebSocket/WebSocketSession";
import { Contact } from "../../Models/Contact";
import { Meeting } from "../../Models/Meeting";

const BookingDetails = () => {
  const classes = useStyles();
  const customerId = useCustomerId();
  const customerContent = useCustomerContent();
  const [authenticatedUser] = useAuthenticatedUser();
  const [userId] = useQueryParam("userId", StringParam);
  const [contactId] = useQueryParam("contactId", StringParam);
  const [meetingId] = useQueryParam("meetingId", StringParam);
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting>();
  const [meetings, setMeetings] = useState<Meeting[]>();
  const [contact, setContact] = useState<Contact>();
  const [pagination, setPagination] = useState<Pagination>();
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const setSpinner = useSpinner()[1];
  const intl = useIntl();
  const [isTodoLoading, setIsTodoLoading] = useState<boolean>(false);
  const [isChatLoading, setIsChatLoading] = useState<boolean>(false);
  const [isContactLoading, setIsContactLoading] = useState<boolean>(false);
  const [isMeetingsLoading, setIsMeetingsLoading] = useState<boolean>(false);
  const [isSharedFilesLoading, setIsSharedFilesLoading] = useState<boolean>(
    false
  );
  const [isPrivateNotesLoading, setIsPrivateNotesLoading] = useState<boolean>(
    false
  );
  const isLoading = (): boolean => {
    return (
      isTodoLoading ||
      isChatLoading ||
      isContactLoading ||
      isMeetingsLoading ||
      isSharedFilesLoading ||
      isPrivateNotesLoading
    );
  };

  const loadContact = () => {
    if (
      customerId &&
      authenticatedUser.user &&
      contactId &&
      contactId !== contact?.userId
    ) {
      setIsContactLoading(true);
      contactService()
        .getContactByIdDecorated(authenticatedUser.user, {
          contactId,
          customerId,
        })
        .then((res) => {
          setContact(res);
        })
        .finally(() => setIsContactLoading(false));
    }
  };

  const loadMeeting = () => {
    if (customerId && authenticatedUser.user && meetingId) {
      setIsMeetingsLoading(true);
      meetingService()
        .getMeetingByIdDecorated(authenticatedUser.user, {
          customerId,
          meetingId,
        })
        .then((res) => {
          setSelectedMeeting(res);
          setMeetings([res]);
        })
        .finally(() => setIsMeetingsLoading(false));
    }
  };

  const loadMeetingsWithParticipant = () => {
    if (customerId && userId && authenticatedUser.user?.id) {
      setIsMeetingsLoading(false);
      meetingService()
        .getMeetingsForUserDecorated(authenticatedUser.user, {
          customerId,
          participantId: userId,
          userId: authenticatedUser.user?.id,
          statuses: [MeetingStatusType.Completed, MeetingStatusType.InProgress],
          sortOrder: SortOrderType.Descending,
          pageSize,
          page,
        })
        .then((res) => {
          setPagination(res.pagination);
          setMeetings(res.data);
        })
        .finally(() => setIsMeetingsLoading(false));
    }
  };
  const handleOnItemClick = (meeting?: MeetingResponse) => {
    setSelectedMeeting(meeting);
  };
  useEffect(() => {
    loadMeeting();
    loadMeetingsWithParticipant();
  }, [page, pageSize, meetingId, userId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadContact();
  }, [contactId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSpinner({ isLoading: isLoading() });
  }, [isLoading()]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!meetingId && !userId) {
    return <RedirectToErrorPage />;
  }

  return (
    <WebSocketSession>
      <Grid container spacing={1}>
        {customerContent.feedbackButton?.contents && (
          <Grid item xs={12} lg={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6" className={classes.boldText}>
                {intl.formatMessage(messages.bookingDetailsExperienceText)}
                <Button
                  variant="contained"
                  className={classes.linkButton}
                  href={customerContent.feedbackButton.contents}
                  target="_blank"
                >
                  {intl.formatMessage(messages.bookingDetailsExperienceButton)}
                </Button>
              </Typography>
            </Paper>
          </Grid>
        )}
        {contactId && (
          <Grid item xs={12}>
            <ParticipantCard
              variant={Variant.MEETINGS_OVERVIEW}
              contact={contact}
            />
          </Grid>
        )}
        <Grid item md={3} xs={12}>
          <BookingsListCard
            showSummary={contact !== undefined}
            meetings={meetings}
            selectedMeeting={selectedMeeting}
            onItemClick={handleOnItemClick}
            onPaginationChange={setPage}
            pagination={pagination}
          />
        </Grid>
        <Grid item md={9} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ParticipantCard
                contact={contact}
                meeting={selectedMeeting}
                variant={
                  selectedMeeting
                    ? Variant.MEETING_DETAILS
                    : Variant.PARTICIPANT_OVERVIEW
                }
              />
            </Grid>
            {selectedMeeting?.id ? (
              <>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <ChatCard
                        readOnly
                        meetingId={selectedMeeting?.id}
                        participants={selectedMeeting?.participants}
                        onLoading={() => setIsChatLoading(true)}
                        onLoaded={() => setIsChatLoading(false)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PrivateNotesCard
                        readonly
                        meetingId={selectedMeeting?.id}
                        onLoading={() => setIsPrivateNotesLoading(true)}
                        onLoaded={() => setIsPrivateNotesLoading(false)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TodoCard
                        readOnly
                        variant="meetingOverview"
                        meetingId={selectedMeeting?.id}
                        participants={selectedMeeting?.participants}
                        onLoading={() => setIsTodoLoading(true)}
                        onLoaded={() => setIsTodoLoading(false)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SharedFilesCard
                        readOnly
                        variant="meetingOverview"
                        meetingId={selectedMeeting?.id}
                        onLoading={() => setIsSharedFilesLoading(true)}
                        onLoaded={() => setIsSharedFilesLoading(false)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={6} xs={12}>
                  {contact && (
                    <TodoCard
                      variant="personOverview"
                      contact={contact}
                      onLoading={() => setIsTodoLoading(true)}
                      onLoaded={() => setIsTodoLoading(false)}
                    />
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  {contact && (
                    <SharedFilesCard
                      variant="personOverview"
                      contact={contact}
                      onLoading={() => setIsSharedFilesLoading(true)}
                      onLoaded={() => setIsSharedFilesLoading(false)}
                    />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </WebSocketSession>
  );
};

export default BookingDetails;
