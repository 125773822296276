/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Property to sort users by
 * @export
 * @enum {string}
 */
export enum SortByType {
    LastName = 'LAST_NAME',
    FirstName = 'FIRST_NAME',
    LastActiveDate = 'LAST_ACTIVE_DATE'
}

export function SortByTypeFromJSON(json: any): SortByType {
    return SortByTypeFromJSONTyped(json, false);
}

export function SortByTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortByType {
    return json as SortByType;
}

export function SortByTypeToJSON(value?: SortByType | null): any {
    return value as any;
}

