import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  dialogTitle: {
    id: "dialog.updatePassword.title",
    defaultMessage: "Update password",
    description: "titel for dialog ",
  },
  submitButtonLabel: {
    id: "dialog.updatePassword.button.submit",
    defaultMessage: "Update password",
    description: "Submit label for dialog",
  },
  cancelButtonLabel: {
    id: "dialog.updatePassword.button.cancel",
    defaultMessage: "Cancel",
    description: "Cancel label for dialog",
  },
  success: {
    id: "dialog.updatePassword.success",
    defaultMessage: "Password was updated",
    description: "Success message",
  },
  generalError: {
    id: "dialog.updatePassword.generalError",
    defaultMessage: "Something went wrong",
    description: "General error",
  },
});
