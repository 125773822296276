import { defineMessages } from "react-intl";

export const messages = defineMessages({
  personalSectionTitle: {
    id: "userfields.personaltitle",
    defaultMessage: "Personal information",
    description: "Title for personal details section in user form",
  },
  caregiverSectionTitle: {
    id: "userfields.caregivertitle",
    defaultMessage: "Profile",
    description: "Title for fields for professionals in user form",
  },
  personNumberLabel: {
    id: "userfields.personnumber.label",
    defaultMessage: "Personal identity number",
    description: "label for personnumber field",
  },
  hsaIdLabel: {
    id: "userfields.hsaId.label",
    defaultMessage: "HSA-ID",
    description: "label for HSA-ID field",
  },
  usernameLabel: {
    id: "userfields.username.label",
    defaultMessage: "Username",
    description: "label for username field",
  },
  firstNameLabel: {
    id: "userfields.firstname.label",
    defaultMessage: "First name",
    description: "label for first name field",
  },
  lastNameLabel: {
    id: "userfields.lastname.label",
    defaultMessage: "Last name",
    description: "label for last name field",
  },
  workTitleLabel: {
    id: "userfields.worktitle.label",
    defaultMessage: "Work title",
    description: "label for work title field",
  },
  phoneLabel: {
    id: "userfields.phone.label",
    defaultMessage: "Phone",
    description: "label for phone field",
  },
  emailLabel: {
    id: "userfields.email.label",
    defaultMessage: "Email",
    description: "label for email field",
  },
  focusAreasLabel: {
    id: "userfields.focusareas.label",
    defaultMessage: "Focus areas",
    description: "label for focus areas field",
  },
  userRolesLabel: {
    id: "userfields.userroles.label",
    defaultMessage: "User roles",
    description: "label for user roles field",
  },
  additionalInformationLabel: {
    id: "userfields.additionalinformation.label",
    defaultMessage: "Additional information",
    description: "label for additional information field",
  },
});
