import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import ProfilePicture from "../../ProfilePicture/ProfilePicture";
import { displayUser } from "../../../Utils/User";
import { FileUserResponse } from "../../../generated/file-api";
import { useCustomerFeatures } from "../../../Providers/CustomerProvider/CustomerProvider";
import { hasFeatureEnabled } from "../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../generated/customersettings-api";

interface Props {
  users?: FileUserResponse[];
}

const FileUserList = (props: Props) => {
  const { users } = props;
  const customerFeatures = useCustomerFeatures();
  const doFormatPersonNumber = hasFeatureEnabled(
    CustomerFeatureType.UsesPersonNumber,
    customerFeatures
  );

  if (!users) {
    return null;
  }

  return (
    <List component="nav" aria-label="list of all participants">
      {users.map((p) => (
        <ListItem>
          <ListItemAvatar>
            <ProfilePicture firstName={p.firstName} lastName={p.lastName} />
          </ListItemAvatar>
          <ListItemText
            primary={<span>{displayUser(doFormatPersonNumber, p, true)}</span>}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default FileUserList;
