import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { MeetingResponse } from "../../../../generated/meeting-api";
import { useStyles } from "./OngoingMeetingCard.style";
import { messages } from "./OngoingMeetingCard.messages";
import {
  getFormattedDate,
  getFormattedTimespan,
  getParticipantsAfterRemoval,
} from "../../../../Utils/Meeting";
import { displayUser } from "../../../../Utils/User";
import { Variant } from "../../../../Components/BookingsList/Components/Variant/Variant";
import ConnectToMeetingButton from "../../../../Components/Buttons/ConnectToMeetingButton/ConnectToMeetingButton";
import AvatarGroup from "../../../../Components/AvatarGroup/AvatarGroup";
import { useCustomerFeatures } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { hasFeatureEnabled } from "../../../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";

interface Props {
  bookings?: MeetingResponse[];
}
const OngoingMeetingCard = (props: Props) => {
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const { bookings } = props;
  const customerFeatures = useCustomerFeatures();
  const doFormatPersonNumber = hasFeatureEnabled(
    CustomerFeatureType.UsesPersonNumber,
    customerFeatures
  );
  const filteredParticipants = (meeting: MeetingResponse) =>
    getParticipantsAfterRemoval(meeting?.participants || [], [
      authenticatedUser.user?.id || "",
    ]);

  if (!bookings || bookings.length === 0) {
    return <></>;
  }

  const CustomAvatar = (meeting: MeetingResponse) => {
    const { participants, title } = meeting;
    const meetingParticipants = getParticipantsAfterRemoval(
      participants || [],
      [authenticatedUser.user?.id || ""]
    );

    if (meetingParticipants.length > 1) {
      return (
        <>
          <Typography variant="h5" className={classes.informationText}>
            {title}
          </Typography>
          <div className={classes.informationText}>
            <AvatarGroup participants={participants} max={10} center />
          </div>
        </>
      );
    }

    return (
      <>
        <Typography variant="h5" className={classes.informationText}>
          {title}
        </Typography>
        <Typography variant="subtitle1" className={classes.informationText}>
          {displayUser(doFormatPersonNumber, filteredParticipants(meeting)[0])}
        </Typography>
      </>
    );
  };

  return (
    <>
      {bookings.map((meeting) => (
        <Paper key={meeting.id} className={classes.root}>
          <CustomAvatar {...meeting} />
          <Typography variant="subtitle2" className={classes.informationText}>
            {getFormattedDate(meeting.startDateTime)}{" "}
            {getFormattedTimespan(meeting.startDateTime, meeting.duration)}
          </Typography>
          <ConnectToMeetingButton
            meeting={meeting}
            className={classes.button}
            contextVariant={Variant.DASHBOARD_ONGOING_BOOKINGS}
            overrideLabel={messages.buttonLabel}
          />
        </Paper>
      ))}
    </>
  );
};

export default OngoingMeetingCard;
