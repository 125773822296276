import React, { useEffect, useState } from "react";
import { Button, Chip, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import { useCustomerId } from "../../../../Providers/CustomerProvider/CustomerProvider";
import {
  useAuthenticatedUser,
  useLogout,
} from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useStyles } from "./ConsentSettings.style";
import {
  CustomerAgreementResponse,
  CustomerUserAgreementType,
  UserAgreementConsentResponse,
} from "../../../../generated/userconsent-api";
import { getFormattedDate } from "../../../../Utils/Meeting";
import { messages } from "./ConsentSettings.messages";
import { getUserAgreementWithType } from "../../../../Utils/Agreements";
import { userConsentService } from "../../../../Providers/ServiceProvider/ServiceProvider";

const ConsentSettings = () => {
  const intl = useIntl();
  const [authenticatedUser] = useAuthenticatedUser();
  const customerId = useCustomerId();
  const logout = useLogout();
  const classes = useStyles();
  const [
    customerAgreementResponse,
    setCustomerAgreementResponse,
  ] = useState<CustomerAgreementResponse>();
  const [
    userAgreementConsent,
    setUserAgreementConsent,
  ] = useState<UserAgreementConsentResponse>();

  const revokeConsent = () => {
    if (customerId && authenticatedUser.user?.id) {
      userConsentService()
        .revokeUserConsentOnCustomer({
          userId: authenticatedUser.user.id,
          customerId,
        })
        .then(logout);
    }
  };

  const loadCustomerConsent = (agreementId: string) => {
    if (customerId) {
      userConsentService()
        .getCustomerUserAgreement({
          customerId,
          agreementId,
        })
        .then(setCustomerAgreementResponse);
    }
  };

  const loadUserAgreementConsent = () => {
    if (customerId && authenticatedUser.user?.id) {
      userConsentService()
        .checkUserConsentOnCustomer({
          userId: authenticatedUser.user?.id,
          customerId,
        })
        .then((res) => {
          const agreement = getUserAgreementWithType(
            CustomerUserAgreementType.ConsentAgreement,
            res
          );
          if (agreement) {
            setUserAgreementConsent(agreement);
          }
        });
    }
  };

  useEffect(() => {
    if (userAgreementConsent?.consentedTo) {
      loadCustomerConsent(userAgreementConsent?.consentedTo);
    }
  }, [userAgreementConsent]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadUserAgreementConsent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {customerAgreementResponse?.validFrom &&
        userAgreementConsent?.consentGivenOn &&
        userAgreementConsent?.status && (
          <div className={classes.chipContainer}>
            <Chip
              label={`${intl.formatMessage(
                messages.consentGivenOnLabel
              )}: ${getFormattedDate(userAgreementConsent.consentGivenOn)}`}
            />
          </div>
        )}
      <Typography component="h1" variant="h5" paragraph>
        {customerAgreementResponse?.agreementTitle.toUpperCase()}
      </Typography>
      {customerAgreementResponse?.agreementText && (
        <ReactQuill
          readOnly
          theme="snow"
          modules={{ toolbar: false }}
          value={DOMPurify.sanitize(customerAgreementResponse.agreementText)}
        />
      )}
      {/* TODO should be hidden until we have implemented an automatic solution for removing all userdata from DB */}
      {false && (
        <Button
          variant="contained"
          onClick={revokeConsent}
          className={classes.button}
        >
          {intl.formatMessage(messages.revokeLabel)}
        </Button>
      )}
    </>
  );
};

export default ConsentSettings;
