import { useIntl } from "react-intl";
import { messages } from "../../Views/CustomerSettings/CustomerSettings.messages";
import { CustomerFeatureType } from "../../generated/customersettings-api";

interface CustomerFeaturesConfig {
  label: string;
  feature: CustomerFeatureType;
}

export const FunctionsConfig = (): CustomerFeaturesConfig[] => {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage(messages.inMeetingFileShare),
      feature: CustomerFeatureType.InMeetingFileShare,
    },
    {
      label: intl.formatMessage(messages.messages),
      feature: CustomerFeatureType.Messages,
    },
    {
      label: intl.formatMessage(messages.oneWayMessage),
      feature: CustomerFeatureType.OneWayMessage,
    },
  ];
};

export const MeetingRoomConfig = (): CustomerFeaturesConfig[] => {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage(messages.inMeetingChat),
      feature: CustomerFeatureType.InMeetingChat,
    },
    {
      label: intl.formatMessage(messages.inMeetingNotes),
      feature: CustomerFeatureType.InMeetingNotes,
    },
    {
      label: intl.formatMessage(messages.inMeetingTodo),
      feature: CustomerFeatureType.InMeetingTodo,
    },
    {
      label: intl.formatMessage(messages.inMeetingScreenShareClient),
      feature: CustomerFeatureType.InMeetingScreenShareClient,
    },
    {
      label: intl.formatMessage(messages.inMeetingScreenShareStaff),
      feature: CustomerFeatureType.InMeetingScreenShareStaff,
    },
    {
      label: intl.formatMessage(messages.usesMeetingConfirmation),
      feature: CustomerFeatureType.UsesMeetingConfirmation,
    },
    {
      label: intl.formatMessage(messages.allowExternalGroupBookings),
      feature: CustomerFeatureType.AllowExternalGroupBookings,
    },
  ];
};

export const UserManagementConfig = (): CustomerFeaturesConfig[] => {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage(messages.externalUsers),
      feature: CustomerFeatureType.ExternalUsers,
    },
    {
      label: intl.formatMessage(messages.inviteExternal),
      feature: CustomerFeatureType.InviteExternal,
    },
    {
      label: intl.formatMessage(messages.inviteClient),
      feature: CustomerFeatureType.InviteClient,
    },
    {
      label: intl.formatMessage(messages.inviteStaff),
      feature: CustomerFeatureType.InviteStaff,
    },
    {
      label: intl.formatMessage(messages.usesPersonNumber),
      feature: CustomerFeatureType.UsesPersonNumber,
    },
    {
      label: intl.formatMessage(messages.usesHsaId),
      feature: CustomerFeatureType.UsesHsaId,
    },
    {
      label: intl.formatMessage(messages.updateClientsViaContacts),
      feature: CustomerFeatureType.UpdateClientsViaContacts,
    },
    {
      label: intl.formatMessage(messages.updateExternalsViaContacts),
      feature: CustomerFeatureType.UpdateExternalsViaContacts,
    },
    {
      label: intl.formatMessage(messages.adminBookingList),
      feature: CustomerFeatureType.AdminBookingList,
    },
    {
      label: intl.formatMessage(messages.autoCreatePasswordClient),
      feature: CustomerFeatureType.AutoCreatePasswordClient,
    },
    {
      label: intl.formatMessage(messages.autoCreatePasswordStaff),
      feature: CustomerFeatureType.AutoCreatePasswordStaff,
    },
    {
      label: intl.formatMessage(messages.autoCreatePasswordExternal),
      feature: CustomerFeatureType.AutoCreatePasswordExternal,
    },
  ];
};

export const LoginConfig = (): CustomerFeaturesConfig[] => {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage(messages.bankIdLoginClient),
      feature: CustomerFeatureType.BankIdLoginClient,
    },
    {
      label: intl.formatMessage(messages.bankIdLoginStaff),
      feature: CustomerFeatureType.BankIdLoginStaff,
    },
    {
      label: intl.formatMessage(messages.bankIdLoginExternal),
      feature: CustomerFeatureType.BankIdLoginExternal,
    },
    {
      label: intl.formatMessage(messages.sithsLogin),
      feature: CustomerFeatureType.SithsLogin,
    },
    {
      label: intl.formatMessage(messages.usernameLoginClient),
      feature: CustomerFeatureType.UsernameLoginClient,
    },
    {
      label: intl.formatMessage(messages.usernameLoginStaff),
      feature: CustomerFeatureType.UsernameLoginStaff,
    },
    {
      label: intl.formatMessage(messages.usernameLoginExternal),
      feature: CustomerFeatureType.UsernameLoginExternal,
    },
  ];
};

export const MiscellaneousConfig = (): CustomerFeaturesConfig[] => {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage(messages.usesCustomDomain),
      feature: CustomerFeatureType.UsesCustomDomain,
    },
    {
      label: intl.formatMessage(messages.takeCareSync),
      feature: CustomerFeatureType.TakeCareSync,
    },
    {
      label: intl.formatMessage(messages.webdocUpdateBookingButton),
      feature: CustomerFeatureType.WebdocUpdateBookingButton,
    },
    {
      label: intl.formatMessage(messages.debugView),
      feature: CustomerFeatureType.DebugView,
    },
  ];
};
