import { defineMessages } from "react-intl";

export const messages = defineMessages({
  alertPasswordLoginNotActivated: {
    id: "usernameAndPassword.alert.passwordNotActivated.text",
    defaultMessage:
      "Username and password login is not activated for yor account",
    description: "alert text for username password login not activated",
  },
  updateUsernameTitle: {
    id: "usernameAndPassword.updateUsername.title",
    defaultMessage: "Username",
    description: "UpdateUsername title",
  },
  updatePasswordTitle: {
    id: "usernameAndPassword.updatePassword.title",
    defaultMessage: "Password",
    description: "UpdatePassword title",
  },
  updateUsernameButton: {
    id: "usernameAndPassword.button.updateUsername",
    defaultMessage: "Update username",
    description: "Button label for updateUsername",
  },
  updatePasswordButton: {
    id: "usernameAndPassword.button.updatePassword",
    defaultMessage: "Update password",
    description: "Button label for updatePassword",
  },
});
