import { defineMessages } from "react-intl";

export const messages = defineMessages({
  warningMessage: {
    id: "validation.meetingForm.participantsRequired",
    defaultMessage:
      "At least two participants are required, and one of them must be a caregiver",
    description: "participant warning message",
  },
});
