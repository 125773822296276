import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FormikProps } from "formik";
import { useIntl } from "react-intl";
import PhoneInput, { CountryData } from "react-phone-input-2";
import examples from "libphonenumber-js/mobile/examples";
import {
  getExampleNumber,
  parsePhoneNumberFromString,
} from "libphonenumber-js";
import { Typography } from "@mui/material";
import { useStyles } from "./PhoneNumberField.style";
import "./css/material.css";
import { useCustomerMarket } from "../../../Providers/CustomerProvider/CustomerProvider";
import { toShorthand } from "../../../Utils/Market";
import { messages } from "../../UserForm/Components/Validation/Validation.messages";
import { Market } from "../../../generated/customer-api";

interface CountryDataInfo extends CountryData {
  iso2: string;
}

interface Props {
  id: string;
  label: string;
  disabledFields?: string[];
  formik: FormikProps<any>;
}

const PhoneNumberField = (props: Props) => {
  const intl = useIntl();
  const market = useCustomerMarket();
  const classes = useStyles();
  const { id, label, formik, disabledFields } = props;
  const { touched } = formik;
  const [isValid, setIsValid] = useState<boolean>();
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [country] = useState<string>(toShorthand(market));
  const [number, setNumber] = useState<string>(formik.values[id]);

  const handleChange = (phoneNumber: string, countryData: CountryData) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      phoneNumber,
      // @ts-ignore
      countryData.countryCode.toUpperCase()
    );

    setNumber(phoneNumber);
    formik.setFieldError(id, intl.formatMessage(messages.phoneNumberInvalid));
    formik.setFieldValue(id, parsedPhoneNumber?.number);
    setIsEmpty(phoneNumber === countryData.countryCode || phoneNumber === "");
    setIsValid(parsedPhoneNumber?.isValid());
    const isPhoneEmpty =
      phoneNumber === countryData.countryCode || phoneNumber === "";
    const isPhoneValid = parsedPhoneNumber?.isValid();

    if (!isPhoneEmpty && !isPhoneValid && touched[id]) {
      formik.setFieldError(id, intl.formatMessage(messages.phoneNumberInvalid));
    } else if (market === Market.Morocco && isPhoneEmpty && touched[id]) {
      formik.setFieldError(
        id,
        intl.formatMessage(messages.phoneNumberRequired)
      );
    }
  };

  const handleIsValid = (phoneNumber: string, countryData: CountryDataInfo) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      phoneNumber,
      // @ts-ignore
      countryData.iso2.toUpperCase()
    );

    // console.log("HANDLE_IS_VALID", parsedPhoneNumber?.isValid());
    const isPhoneValid = parsedPhoneNumber?.isValid();

    if (phoneNumber.length > 0 && !isPhoneValid && touched[id]) {
      return false;
    }
    if (market === Market.Morocco && phoneNumber.length > 0 && touched[id]) {
      return false;
    }
    return true;
  };

  if (!market) {
    return null;
  }

  return (
    <>
      <PhoneInput
        placeholder={
          // @ts-ignore
          getExampleNumber(country.toUpperCase(), examples)?.number.replace(
            "+",
            ""
          )
        }
        inputProps={{ id }}
        specialLabel={label}
        autoFormat={false}
        disableCountryCode
        // @ts-ignore
        inputStyle={{ width: "100%" }}
        country={country}
        value={number}
        onChange={handleChange}
        onFocus={() => formik.setFieldTouched(id, false)}
        onBlur={() => formik.setFieldTouched(id, true)}
        disabled={disabledFields?.includes(id)}
        // @ts-ignore
        isValid={handleIsValid}
        disableDropdown
      />
      {!isEmpty && !isValid && touched[id] && (
        <Typography className={classes.errorMessage} fontSize="smaller">
          {intl.formatMessage(messages.phoneNumberInvalid)}
        </Typography>
      )}
      {market === Market.Morocco && isEmpty && touched[id] && (
        <Typography className={classes.errorMessage} fontSize="smaller">
          {intl.formatMessage(messages.phoneNumberRequired)}
        </Typography>
      )}
      <input hidden value={formik.values[id]} />
    </>
  );
};

export default PhoneNumberField;
