import React from "react";
import {
  Badge,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./MessagesCard.style";
import { messages } from "./MessagesCard.messages";
import { getThreadCounterPart } from "../../../../Utils/Message";
import ProfilePicture from "../../../../Components/ProfilePicture/ProfilePicture";
import { displayUser } from "../../../../Utils/User";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  useCustomerFeatures,
  useCustomerId,
} from "../../../../Providers/CustomerProvider/CustomerProvider";
import CardHeading from "../../../../Components/CardHeading/CardHeading";
import { redirectToMessage } from "../../../Messages/Messages";
import { MessageThreadResponse } from "../../../../generated/message-api";
import { messageService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import { hasFeatureEnabled } from "../../../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";

interface Props {
  messageThreads?: MessageThreadResponse[];
}

const MessagesCard = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { messageThreads } = props;
  const [authenticatedUser] = useAuthenticatedUser();
  const customerId = useCustomerId();
  const customerFeatures = useCustomerFeatures();
  const doFormatPersonNumber = hasFeatureEnabled(
    CustomerFeatureType.UsesPersonNumber,
    customerFeatures
  );

  const handleRedirect = (messageId: string) => {
    if (customerId) {
      messageService()
        .markMessagesAsRead({
          customerId,
          markMessagesAsReadRequest: {
            includeAllReplyMessages: true,
            messageIds: [messageId],
          },
        })
        .finally(() => redirectToMessage(messageId));
    }
  };

  const mapMessageThreads = messageThreads?.map((threads) => {
    const threadCounterPart = getThreadCounterPart(
      threads,
      authenticatedUser.user?.id
    );
    return (
      <>
        <ListItem
          button
          onClick={() => handleRedirect(threads.id)}
          className={classes.root}
        >
          <Badge
            badgeContent={threads.nbrOfUnreadMessages}
            color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <ListItemAvatar>
              <ProfilePicture
                firstName={threadCounterPart?.firstName}
                lastName={threadCounterPart?.lastName}
              />
            </ListItemAvatar>
          </Badge>
          <ListItemText
            primary={threads.subject}
            secondary={displayUser(doFormatPersonNumber, threadCounterPart)}
          />
        </ListItem>
        <Divider />
      </>
    );
  });

  if (!messageThreads || messageThreads.length < 1) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <CardHeading noMarginBottom>
        {intl.formatMessage(messages.title).toUpperCase()}
      </CardHeading>
      <List className={classes.list}>{mapMessageThreads}</List>
    </Paper>
  );
};

export default MessagesCard;
