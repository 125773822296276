/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Sort order of users
 * @export
 * @enum {string}
 */
export enum SortOrderType {
    Ascending = 'ASCENDING',
    Descending = 'DESCENDING'
}

export function SortOrderTypeFromJSON(json: any): SortOrderType {
    return SortOrderTypeFromJSONTyped(json, false);
}

export function SortOrderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortOrderType {
    return json as SortOrderType;
}

export function SortOrderTypeToJSON(value?: SortOrderType | null): any {
    return value as any;
}

