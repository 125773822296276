import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import List from "@material-ui/core/List";
import { Button, ListItem } from "@material-ui/core";
import { hasFeatureEnabled } from "../FeatureController/FeatureController";
import {
  CustomerFeature,
  CustomerFeatureType,
} from "../../generated/customersettings-api";
import { useCustomerFeatures } from "../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  children: React.ReactNode;
}

export enum Severity {
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export interface Log {
  severity: Severity;
  timestamp: Date;
  message?: any;
  optionalParams: any[];
}

export const DebugConsoleVisibilityContext = React.createContext<
  [boolean, Dispatch<SetStateAction<boolean>>]
>([false, () => {}]);

export const DebugConsoleContext = React.createContext<
  [Log[], Dispatch<SetStateAction<Log[]>>]
>([[], () => {}]);

export const useDebugConsoleVisibilityContext = () => {
  return useContext(DebugConsoleVisibilityContext);
};
export const useDebugConsoleContext = () => {
  return useContext(DebugConsoleContext);
};

export function useDebugConsole() {
  const [isEnabled, setIsEnabled] = useDebugConsoleVisibilityContext();
  return { isEnabled, setIsEnabled };
}

export function useLogger(features: CustomerFeature[]) {
  const [logs, setLogs] = useDebugConsoleContext();

  const addLog = (log: Log) => {
    if (hasFeatureEnabled(CustomerFeatureType.DebugView, features)) {
      setLogs((prevState) => [...prevState, log]);
    }
  };

  const log = (severity: Severity, message?: any, ...optionalParams: any[]) => {
    switch (severity) {
      case Severity.INFO:
        // eslint-disable-next-line no-console
        console.info(message, optionalParams);
        break;
      case Severity.WARNING:
        // eslint-disable-next-line no-console
        console.warn(message, optionalParams);
        break;
      case Severity.ERROR:
        // eslint-disable-next-line no-console
        console.error(message, optionalParams);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(message, optionalParams);
    }
    addLog({ timestamp: new Date(), severity, message, optionalParams });
  };

  return { logs, setLogs, log };
}

const DebugConsole = (props: Props) => {
  const { children } = props;
  const customerFeatures = useCustomerFeatures();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [logs, setLogs] = useState<Log[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  if (!hasFeatureEnabled(CustomerFeatureType.DebugView, customerFeatures)) {
    return <>{children}</>;
  }

  const RenderListItem = (log: Log) => {
    const { severity, message, optionalParams, timestamp } = log;
    return (
      <ListItem key={timestamp.toLocaleDateString()} style={{ padding: 0 }}>
        {`${timestamp.toLocaleTimeString()} - (${severity}) - ${message} - ${optionalParams}`}
      </ListItem>
    );
  };

  return (
    <DebugConsoleVisibilityContext.Provider value={[isEnabled, setIsEnabled]}>
      <DebugConsoleContext.Provider value={[logs, setLogs]}>
        {isEnabled && (
          <>
            <Button onClick={() => setIsOpen(!isOpen)}>Debug Console</Button>
            {isOpen && (
              <>
                <List style={{ maxHeight: "90vl", height: "90vl" }} />
                {logs.map((log) => (
                  <RenderListItem {...log} />
                ))}
              </>
            )}
          </>
        )}
        {children}
      </DebugConsoleContext.Provider>
    </DebugConsoleVisibilityContext.Provider>
  );
};

export default DebugConsole;
