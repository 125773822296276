import React from "react";
import { TextField as MuiTextField, useTheme } from "@material-ui/core";
import { FormikProps } from "formik";

interface Props {
  id: string;
  label: string;
  onBlur?: (event: React.ChangeEvent<any>) => void;
  errorMsg?: string;
  disabledFields?: string[];
  formik: FormikProps<any>;
  inputMode?: "text" | "tel" | "email" | "number";
  multiline?: boolean;
  autoComplete?: "on" | "off";
  placeholder?: string;
}

const TextField = (props: Props) => {
  const {
    id,
    label,
    formik,
    onBlur,
    errorMsg,
    disabledFields,
    inputMode,
    multiline,
    autoComplete,
    placeholder,
  } = props;
  const { errors, touched } = formik;
  const theme = useTheme();

  return (
    <MuiTextField
      id={id}
      name={id}
      label={label}
      InputLabelProps={{
        style: { color: theme.palette.text.secondary },
      }}
      placeholder={placeholder}
      value={formik.values[id]}
      onChange={formik.handleChange}
      onBlur={onBlur}
      onFocus={() => formik.setFieldTouched(id, false)}
      autoComplete={autoComplete || "off"}
      error={!!(errors[id] && touched[id])}
      helperText={touched[id] && errors[id] ? errorMsg : null}
      variant="outlined"
      fullWidth
      type={inputMode && inputMode === "number" ? inputMode : undefined}
      inputMode={inputMode && inputMode !== "number" ? inputMode : "text"}
      disabled={disabledFields?.includes(id)}
      multiline={multiline}
    />
  );
};

export default TextField;
