import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import jwt_decode from "jwt-decode";
import { differenceInMilliseconds } from "date-fns";
import { useHistory } from "react-router-dom";
import { UserLocaleType, UserResponse } from "../../generated/user-api";
import {
  authenticationService,
  userService,
} from "../ServiceProvider/ServiceProvider";
import {
  useCustomerId,
  useCustomerName,
} from "../CustomerProvider/CustomerProvider";
import {
  getSessionStorageItem,
  Key,
  removeSessionStorageItem,
  setSessionStorageItem,
} from "../../Utils/SessionStorage";
import { RoutePath } from "../../Components/Routes/RoutePath";
import { toShorthand } from "../../Utils/Locale";

interface Props {
  children: React.ReactNode;
  changeLocaleFunction: (newLocale: string) => void;
}

interface ContextType {
  token?: string;
  isComplete?: boolean;
  isLoggedIn?: boolean;
  user?: UserResponse;
}

const initialState: ContextType = {
  token: undefined,
  isComplete: undefined,
  isLoggedIn: undefined,
  user: undefined,
};

const REFRESH_MARGIN = 1000 * 60; // 60sec

const Context = React.createContext<
  [ContextType, Dispatch<SetStateAction<ContextType>>]
>([initialState, () => {}]);

export const useAuthenticatedUser = () => {
  return useContext(Context);
};

const clearSession = () => {
  removeSessionStorageItem(Key.USER_ID);
  removeSessionStorageItem(Key.JWT_TOKEN);
};

export const useLogout = (noReload?: boolean) => {
  const history = useHistory();
  const setState = useContext(Context)[1];
  return () => {
    clearSession();
    setState({
      user: undefined,
      isLoggedIn: false,
      token: undefined,
      isComplete: undefined,
    });
    if (!noReload) {
      window.location.reload();
    } else {
      history.push(RoutePath.LOGIN);
    }
  };
};

const AuthenticatedUserProvider = (props: Props) => {
  const { children, changeLocaleFunction } = props;
  const customerId = useCustomerId();
  const customerName = useCustomerName();
  const [state, setState] = useState(initialState);
  const sessionUserId = getSessionStorageItem(Key.USER_ID);
  const sessionToken = getSessionStorageItem(Key.JWT_TOKEN);

  const isUserComplete = (user?: UserResponse): boolean => {
    return !!(user?.firstName && user?.lastName);
  };

  const getTokenExpireTime = (token?: string): Date => {
    // @ts-ignore
    return token ? new Date(jwt_decode(token).exp * 1000) : new Date();
  };

  const setupTimer = (token?: string) => {
    return setTimeout(() => {
      if (customerId && state.user && token) {
        authenticationService()
          .refreshToken({ customerId })
          .then((res) => {
            if (res.token) {
              setSessionStorageItem(Key.JWT_TOKEN, res.token);
              setupTimer(res.token);
            }
          });
      }
    }, differenceInMilliseconds(getTokenExpireTime(token), new Date()) - REFRESH_MARGIN);
  };

  const handleLocale = (locale: UserLocaleType) => {
    changeLocaleFunction(toShorthand(locale));
    localStorage.setItem("lang", toShorthand(locale));
  };

  useEffect(() => {
    const interval = setupTimer(state.token);
    return () => window.clearInterval(interval);
  }, [state.user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.token && state.user?.id) {
      if (customerName) {
        document.title = customerName;
      }
      setState({
        ...state,
        isLoggedIn: true,
        isComplete: isUserComplete(state.user),
      });
      setSessionStorageItem(Key.USER_ID, state.user?.id);
      setSessionStorageItem(Key.JWT_TOKEN, state.token);
    } else if (sessionToken && sessionUserId && customerId) {
      userService()
        .getUserById({ customerId, userId: sessionUserId })
        .then((res) => {
          setState({
            ...state,
            token: sessionToken,
            user: res,
            isComplete: isUserComplete(state.user),
          });
          handleLocale(res.locale);
        })
        .catch(() => clearSession());
    } else {
      setState({ ...state, isLoggedIn: false });
    }
  }, [state.token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Context.Provider value={[state, setState]}>{children}</Context.Provider>
  );
};

export default AuthenticatedUserProvider;
