/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerFeature,
    CustomerFeatureFromJSON,
    CustomerFeatureFromJSONTyped,
    CustomerFeatureToJSON,
    CustomerThemeResponse,
    CustomerThemeResponseFromJSON,
    CustomerThemeResponseFromJSONTyped,
    CustomerThemeResponseToJSON,
    DefaultLocale,
    DefaultLocaleFromJSON,
    DefaultLocaleFromJSONTyped,
    DefaultLocaleToJSON,
    Market,
    MarketFromJSON,
    MarketFromJSONTyped,
    MarketToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerSettingsResponse
 */
export interface CustomerSettingsResponse {
    /**
     * UUID of customer
     * @type {string}
     * @memberof CustomerSettingsResponse
     */
    id: string;
    /**
     * The full name of the customer
     * @type {string}
     * @memberof CustomerSettingsResponse
     */
    customerName: string;
    /**
     * Organization number of customer
     * @type {string}
     * @memberof CustomerSettingsResponse
     */
    organizationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSettingsResponse
     */
    subDomain?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSettingsResponse
     */
    customDomain?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerSettingsResponse
     */
    isAdminPortal?: boolean;
    /**
     * 
     * @type {DefaultLocale}
     * @memberof CustomerSettingsResponse
     */
    defaultLocale: DefaultLocale;
    /**
     * 
     * @type {Market}
     * @memberof CustomerSettingsResponse
     */
    market?: Market;
    /**
     * 
     * @type {CustomerThemeResponse}
     * @memberof CustomerSettingsResponse
     */
    theme?: CustomerThemeResponse;
    /**
     * List of customer features
     * @type {Array<CustomerFeature>}
     * @memberof CustomerSettingsResponse
     */
    features?: Array<CustomerFeature>;
}

export function CustomerSettingsResponseFromJSON(json: any): CustomerSettingsResponse {
    return CustomerSettingsResponseFromJSONTyped(json, false);
}

export function CustomerSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerSettingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerName': json['customerName'],
        'organizationNumber': !exists(json, 'organizationNumber') ? undefined : json['organizationNumber'],
        'subDomain': !exists(json, 'subDomain') ? undefined : json['subDomain'],
        'customDomain': !exists(json, 'customDomain') ? undefined : json['customDomain'],
        'isAdminPortal': !exists(json, 'isAdminPortal') ? undefined : json['isAdminPortal'],
        'defaultLocale': DefaultLocaleFromJSON(json['defaultLocale']),
        'market': !exists(json, 'market') ? undefined : MarketFromJSON(json['market']),
        'theme': !exists(json, 'theme') ? undefined : CustomerThemeResponseFromJSON(json['theme']),
        'features': !exists(json, 'features') ? undefined : ((json['features'] as Array<any>).map(CustomerFeatureFromJSON)),
    };
}

export function CustomerSettingsResponseToJSON(value?: CustomerSettingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerName': value.customerName,
        'organizationNumber': value.organizationNumber,
        'subDomain': value.subDomain,
        'customDomain': value.customDomain,
        'isAdminPortal': value.isAdminPortal,
        'defaultLocale': DefaultLocaleToJSON(value.defaultLocale),
        'market': MarketToJSON(value.market),
        'theme': CustomerThemeResponseToJSON(value.theme),
        'features': value.features === undefined ? undefined : ((value.features as Array<any>).map(CustomerFeatureToJSON)),
    };
}


