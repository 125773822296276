import * as Yup from "yup";

// text string is used as a key to fetch intl message
const UpdateUserValidationSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .max(50, "firstNameTooLong")
      .required("firstNameRequired"),
    lastName: Yup.string()
      .max(50, "lastNameTooLong")
      .required("lastNameRequired"),
    email: Yup.string().email("emailInvalid"),
  });
};

export default UpdateUserValidationSchema;
