import React, { useState } from "react";
import {
  InputAdornment,
  TextField as MuiTextField,
  useTheme,
} from "@material-ui/core";
import { FormikProps } from "formik";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface Props {
  id: string;
  label: string;
  onBlur?: (event: React.ChangeEvent<any>) => void;
  errorMsg?: string;
  disabledFields?: string[];
  formik: FormikProps<any>;
  placeholder?: string;
}

const PasswordField = (props: Props) => {
  const {
    id,
    label,
    formik,
    onBlur,
    errorMsg,
    disabledFields,
    placeholder,
  } = props;
  const { errors, touched } = formik;
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <MuiTextField
      id={id}
      name={id}
      label={label}
      InputLabelProps={{
        style: { color: theme.palette.text.secondary },
      }}
      placeholder={placeholder}
      value={formik.values[id]}
      onChange={formik.handleChange}
      onBlur={onBlur}
      onFocus={() => formik.setFieldTouched(id, false)}
      error={!!(errors[id] && touched[id])}
      helperText={touched[id] && errors[id] ? errorMsg : null}
      variant="outlined"
      fullWidth
      type={showPassword ? "text" : "password"}
      inputMode="text"
      disabled={disabledFields?.includes(id)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
