import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { Alert } from "@mui/material";
import { messages } from "./UsernameLogin.messages";
import {
  useCustomerId,
  useCustomerName,
} from "../../../../Providers/CustomerProvider/CustomerProvider";
import { authenticationService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import UsernameLoginFields, {
  FormikValues,
} from "../../../../Components/Fields/UsernameLoginFields/UsernameLoginFields";
import UserSelection from "../../../Authentication/Components/UserSelection/UserSelection";
import {
  AuthenticatedUser,
  VerifyCodeResponse,
} from "../../../../generated/authentication-api";
import { useStyles } from "./UsernameLogin.style";
import FeatureController from "../../../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";
import VerificationCodeField from "../../../../Components/Fields/VerificationCodeField/VerificationCodeField";
import { RoutePath } from "../../../../Components/Routes/RoutePath";
import { Key, setSessionStorageItem } from "../../../../Utils/SessionStorage";

interface Props {
  onClick: () => void;
  onCancel: () => void;
  onError: (errorMsg?: string) => void;
}

const UsernameLogin = (props: Props) => {
  const { onClick, onCancel, onError } = props;
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const customerName = useCustomerName();
  const [sessionId, setSessionId] = useState<string>();
  const [isSelected, setIsSelected] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [selectedUser, setSelectedUser] = useState<AuthenticatedUser>();
  const [users, setUsers] = useState<AuthenticatedUser[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: FormikValues = {
    username: "",
    password: "",
  };

  const initializeAuthenticatedUser = (
    verifyCodeResponse: VerifyCodeResponse
  ) => {
    if (customerId) {
      setSessionStorageItem(Key.JWT_TOKEN, verifyCodeResponse.token).then(() =>
        setSessionStorageItem(Key.USER_ID, verifyCodeResponse.userId).then(
          () => {
            window.location.href = RoutePath.DASHBOARD;
          }
        )
      );
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (customerId) {
        authenticationService()
          .authenticateUser({
            customerId,
            userAuthenticationRequest: {
              username: values.username,
              password: values.password,
            },
          })
          .then((res) => {
            setSessionId(res.sessionId);
            setUsers(res.authenticatedUsers || []);
          })
          .catch((res) => {
            if (res.status === 401) {
              enqueueSnackbar(intl.formatMessage(messages.noUserFoundError), {
                variant: "error",
              });
            } else {
              enqueueSnackbar(intl.formatMessage(messages.generalError), {
                variant: "error",
              });
            }
          });
      }
    },
  });

  const verifyCode = (code: string) => {
    if (customerId && sessionId && selectedUser) {
      authenticationService()
        .verifyCode({
          customerId,
          verifyCodeRequest: { sessionId, code, userId: selectedUser.userId },
        })
        .then((res) => initializeAuthenticatedUser(res))
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          });
        });
    }
  };

  const handleOnClick = () => {
    setIsSelected(true);
    onClick();
  };

  const handleOnCancel = () => {
    setIsSelected(false);
    setIsCodeSent(false);
    setSessionId(undefined);
    setSelectedUser(undefined);
    setUsers([]);
    formik.setFieldValue("username", "");
    formik.setFieldValue("password", "");
    onCancel();
  };

  const handleSelectUser = (user: AuthenticatedUser) => {
    setSelectedUser(user);
    if (customerId && sessionId) {
      authenticationService()
        .generateCode({
          customerId,
          generateCodeRequest: {
            sessionId,
            userId: user.userId,
          },
        })
        .then(() => setIsCodeSent(true))
        .catch((error) => {
          if (error.status === 409) {
            onError(intl.formatMessage(messages.noPhoneNumberError));
            handleOnCancel();
          } else {
            enqueueSnackbar(intl.formatMessage(messages.generalError), {
              variant: "error",
            });
          }
        });
    }
  };

  useEffect(() => {
    // Remove all current errors if selected
    if (isSelected) {
      onError(undefined);
    }
  }, [isSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isSelected) {
    return (
      <FeatureController
        requiredAnyOfFeatures={[
          CustomerFeatureType.UsernameLoginClient,
          CustomerFeatureType.UsernameLoginStaff,
          CustomerFeatureType.UsernameLoginExternal,
        ]}
      >
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Button
              onClick={handleOnClick}
              variant="contained"
              className={classes.loginUsernameButton}
            >
              {intl.formatMessage(messages.usernameButton)}
            </Button>
          </Grid>
        </Grid>
      </FeatureController>
    );
  }

  if (isCodeSent) {
    return (
      <>
        <VerificationCodeField onSubmit={(code) => verifyCode(code)} />
      </>
    );
  }

  return (
    <>
      {users.length === 0 && (
        <Grid container justify="center">
          <Grid item md={8} xs={12}>
            <Alert className={classes.alert} severity="info">
              {intl.formatMessage(messages.info, { customerName })}
            </Alert>
            <form onSubmit={formik.handleSubmit}>
              <UsernameLoginFields formik={formik} />
              <div className={classes.buttonContainer}>
                <Button variant="contained" type="submit" color="primary">
                  {intl.formatMessage(messages.submitButtonLabel)}
                </Button>
                <Button onClick={handleOnCancel} color="primary">
                  {intl.formatMessage(messages.cancelButtonLabel)}
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
      )}
      {users.length >= 1 && (
        <UserSelection
          fullWidth
          users={users}
          onUserSelect={(user) => handleSelectUser(user)}
        />
      )}
    </>
  );
};

export default UsernameLogin;
