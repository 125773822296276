import React from "react";
import { Chip } from "@material-ui/core";
import { AutocompleteGetTagProps } from "@material-ui/lab";
import { FormikProps } from "formik";
import { Option } from "../RenderOption/RenderOption";
import { displayUser } from "../../../../../Utils/User";
import { User } from "../../../../../Models/User";
import { useCustomerFeatures } from "../../../../../Providers/CustomerProvider/CustomerProvider";
import { hasFeatureEnabled } from "../../../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../../generated/customersettings-api";

interface Props {
  tagValue: Option[];
  tagProps: AutocompleteGetTagProps;
  size?: "small" | "medium";
  disabledFields?: string[];
  formik: FormikProps<any>;
  meetingLeader?: User;
  userFixedList?: User[];
}

const RenderTags = (props: Props) => {
  const { tagProps, tagValue, size, userFixedList, meetingLeader } = props;
  const customerFeatures = useCustomerFeatures();
  const doFormatPersonNumber = hasFeatureEnabled(
    CustomerFeatureType.UsesPersonNumber,
    customerFeatures
  );

  const getDisabledUserChips = () => {
    const mergedUsers = meetingLeader
      ? [meetingLeader, ...(userFixedList || [])]
      : [...(userFixedList || [])];

    return mergedUsers.map((user) => user?.id);
  };

  return (
    <>
      {tagValue.map((option, index) => {
        return (
          <Chip
            key={option.id}
            size={size}
            label={displayUser(doFormatPersonNumber, option)}
            {...tagProps({ index })}
            disabled={getDisabledUserChips().includes(option.id)}
          />
        );
      })}
    </>
  );
};

export default RenderTags;
