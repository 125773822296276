import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import {
  useAuthenticatedUser,
  useLogout,
} from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  CustomerAgreementResponse,
  CustomerUserAgreementType,
} from "../../generated/userconsent-api";
import { messages } from "./ConsentDialog.messages";
import { useStyles } from "./ConsentDialog.style";
import { userConsentService } from "../../Providers/ServiceProvider/ServiceProvider";

interface Props {
  open: boolean;
  agreementIds: string[];
  onAccepted?: () => void;
}

const ConsentDialog = (props: Props) => {
  const { open, agreementIds, onAccepted } = props;
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const logout = useLogout();
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();
  const [activeStep, setActiveStep] = React.useState(0);
  const [customerAgreementResponses, setCustomerAgreementResponses] = useState<
    CustomerAgreementResponse[]
  >([]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleAccepted = () => {
    enqueueSnackbar(intl.formatMessage(messages.success), {
      variant: "success",
    });
    if (onAccepted) {
      onAccepted();
    }
  };

  const handleAcceptCustomerConsent = (agreementId: string) => {
    if (customerId && authenticatedUser.user?.id) {
      userConsentService()
        .giveUserConsentToCustomerAgreement({
          customerId,
          userAgreementConsentRequest: {
            agreementId,
            userId: authenticatedUser.user.id,
          },
        })
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        )
        .finally(() =>
          agreementIds.length === activeStep + 1
            ? handleAccepted()
            : handleNext()
        );
    }
  };

  const getAgreement = async (
    id: string,
    custId: string
  ): Promise<CustomerAgreementResponse> => {
    return userConsentService().getCustomerUserAgreement({
      agreementId: id,
      customerId: custId,
    });
  };

  useEffect(() => {
    if (customerId && authenticatedUser.user?.id) {
      Promise.all(agreementIds.map((id) => getAgreement(id, customerId))).then(
        setCustomerAgreementResponses
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={open}>
      <DialogTitle>{intl.formatMessage(messages.Title)}</DialogTitle>
      <Stepper activeStep={activeStep} orientation="vertical">
        {customerAgreementResponses.map((agreement) => (
          <Step key={agreement.id}>
            <StepLabel>
              {intl.formatMessage(
                agreement.agreementType ===
                  CustomerUserAgreementType.ConsentAgreement
                  ? messages.consentAgreementLabel
                  : messages.privacyPolicyLabel
              )}
            </StepLabel>
            <StepContent>
              <Typography paragraph variant="h5" component="h1">
                {agreement.agreementTitle}
              </Typography>
              <ReactQuill
                readOnly
                theme="snow"
                modules={{ toolbar: false }}
                value={DOMPurify.sanitize(agreement.agreementText)}
              />
              <div>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={() => handleAcceptCustomerConsent(agreement.id)}
                >
                  {intl.formatMessage(messages.acceptLabel)}
                </Button>
                <Button
                  size="small"
                  color="inherit"
                  variant="outlined"
                  onClick={logout}
                  className={classes.button}
                >
                  {intl.formatMessage(messages.declineLabel)}
                </Button>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Dialog>
  );
};

export default ConsentDialog;
