import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import ReplyIcon from "@material-ui/icons/Reply";
import { messages } from "./ReplyMessagesFields.messages";
import FileUploadButton from "../../../Buttons/FileUploadButton/FileUploadButton";
import { useStyles } from "./ReplyMessageField.style";
import FeatureController, {
  hasFeatureEnabled,
} from "../../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";
import { UserRoleType } from "../../../../generated/user-api";
import PermissionController from "../../../PermissionController/PermissionController";
import { useCustomerFeatures } from "../../../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  threadId?: string;
  disabledFields?: string[];
  onSubmit: (replyMessage: string) => void;
  onFileShared: (file: File) => void;
  activateFileShareWith?: string;
}

const ReplyMessageFields = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const customerFeatures = useCustomerFeatures();
  const [replyMessage, setReplyMessage] = useState<string>("");
  const {
    threadId,
    onSubmit,
    disabledFields,
    activateFileShareWith,
    onFileShared,
  } = props;

  const resetField = () => {
    setReplyMessage("");
  };

  const handleSubmit = () => {
    onSubmit(DOMPurify.sanitize(replyMessage));
    resetField();
  };

  return (
    <>
      {hasFeatureEnabled(
        CustomerFeatureType.OneWayMessage,
        customerFeatures
      ) ? (
        <PermissionController allowedRoles={[UserRoleType.Staff]}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                id="contents"
                placeholder={intl.formatMessage(messages.contentsPlaceholder)}
                value={replyMessage}
                onChange={setReplyMessage}
                readOnly={disabledFields?.includes("contents")}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.rightMargin}
                onClick={handleSubmit}
                size="small"
                color="primary"
                variant="contained"
                startIcon={<ReplyIcon />}
              >
                {intl.formatMessage(messages.replyButton)}
              </Button>
              <FeatureController
                requiredFeature={CustomerFeatureType.InMeetingFileShare}
              >
                {threadId && (
                  <FileUploadButton
                    variant="text"
                    messageThreadId={threadId}
                    accessGivenTo={
                      activateFileShareWith
                        ? [activateFileShareWith]
                        : undefined
                    }
                    onFileUploaded={onFileShared}
                  />
                )}
              </FeatureController>
            </Grid>
          </Grid>{" "}
        </PermissionController>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReactQuill
              theme="snow"
              id="contents"
              placeholder={intl.formatMessage(messages.contentsPlaceholder)}
              value={replyMessage}
              onChange={setReplyMessage}
              readOnly={disabledFields?.includes("contents")}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.rightMargin}
              onClick={handleSubmit}
              size="small"
              color="primary"
              variant="contained"
              startIcon={<ReplyIcon />}
            >
              {intl.formatMessage(messages.replyButton)}
            </Button>
            <FeatureController
              requiredFeature={CustomerFeatureType.InMeetingFileShare}
            >
              {threadId && (
                <FileUploadButton
                  variant="text"
                  messageThreadId={threadId}
                  accessGivenTo={
                    activateFileShareWith ? [activateFileShareWith] : undefined
                  }
                  onFileUploaded={onFileShared}
                />
              )}
            </FeatureController>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ReplyMessageFields;
