import React from "react";
import { Market } from "../../generated/customersettings-api";
import { useCustomerMarket } from "../../Providers/CustomerProvider/CustomerProvider";

interface BaseProps {
  allowedMarkets: Market[];
}
interface Props extends BaseProps {
  children: React.ReactNode;
}

interface PropsAndCurrentUser extends BaseProps {
  currentMarket?: Market;
}

export const hasMarketPermission = (props: PropsAndCurrentUser): boolean => {
  const { currentMarket, allowedMarkets } = props;

  // Accept if user fulfills role requirements and no authority param has been provided
  if (currentMarket && allowedMarkets?.includes(currentMarket)) {
    return true;
  }

  // Deny as default
  return false;
};

const MarketController = (props: Props) => {
  const { children, allowedMarkets } = props;
  const currentMarket = useCustomerMarket();

  if (
    hasMarketPermission({
      currentMarket,
      allowedMarkets,
    })
  ) {
    return <>{children}</>;
  }
  return null;
};

export default MarketController;
