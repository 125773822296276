import React, { useState } from "react";
import { CssBaseline } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import ThemeProvider from "./Components/Theme/ThemeProvider";
import Routes from "./Components/Routes/Routes";
import AppBar from "./Components/AppBar/AppBar";
import CustomFooter from "./Components/CustomFooter/CustomFooter";
import LanguageProvider from "./Translation/LanguageProvider";
import CustomerProvider from "./Providers/CustomerProvider/CustomerProvider";
import AuthenticatedUserProvider from "./Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import ConsentController from "./Components/ConsentController/ConsentController";
import Spinner from "./Components/Spinner/Spinner";
import RecentUpdatesProvider from "./Providers/RecentUpdatesProvider/RecentUpdatesProvider";
import IdleController from "./Components/IdleController/IdleController";
import DebugConsole from "./Components/DebugConsole/DebugConsole";
import CurrentPathProvider from "./Providers/CurrentPathProvider/CurrentPathProvider";

const App = () => {
  const localeFromStorage = localStorage.getItem("lang");
  const initialLocale = localeFromStorage !== null ? localeFromStorage : "sv";
  document.documentElement.lang = initialLocale;
  const [locale, setLocale] = useState(initialLocale);

  return (
    <CustomerProvider changeLocaleFunction={setLocale}>
      <DebugConsole>
        <RecentUpdatesProvider>
          <ThemeProvider>
            <CssBaseline />
            <LanguageProvider locale={locale}>
              <Spinner>
                <SnackbarProvider maxSnack={3}>
                  <AuthenticatedUserProvider changeLocaleFunction={setLocale}>
                    <IdleController>
                      <ConsentController>
                        <CurrentPathProvider>
                          <Routes
                            appBar={<AppBar />}
                            footer={
                              <CustomFooter changeLocaleFunction={setLocale} />
                            }
                          />
                        </CurrentPathProvider>
                      </ConsentController>
                    </IdleController>
                  </AuthenticatedUserProvider>
                </SnackbarProvider>
              </Spinner>
            </LanguageProvider>
          </ThemeProvider>
        </RecentUpdatesProvider>
      </DebugConsole>
    </CustomerProvider>
  );
};

export default App;
