/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserHsaIdRequest
 */
export interface UpdateUserHsaIdRequest {
    /**
     * HSA-ID.
     * @type {string}
     * @memberof UpdateUserHsaIdRequest
     */
    hsaId?: string;
}

export function UpdateUserHsaIdRequestFromJSON(json: any): UpdateUserHsaIdRequest {
    return UpdateUserHsaIdRequestFromJSONTyped(json, false);
}

export function UpdateUserHsaIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserHsaIdRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hsaId': !exists(json, 'hsaId') ? undefined : json['hsaId'],
    };
}

export function UpdateUserHsaIdRequestToJSON(value?: UpdateUserHsaIdRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hsaId': value.hsaId,
    };
}


