import React from "react";
import { Button, Typography } from "@material-ui/core";
import { useStyles } from "./LanguageSwitch.style";
import { userService } from "../../Providers/ServiceProvider/ServiceProvider";
import { toUserLocaleType } from "../../Utils/Locale";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  label: string;
  language: "en" | "sv" | "fr";
  changeLocaleFunction: (newLocale: string) => void;
}

const LanguageSwitch = (props: Props) => {
  const { changeLocaleFunction, label, language } = props;
  const classes = useStyles();
  const authenticatedUser = useAuthenticatedUser()[0];

  const updateLocale = () => {
    if (authenticatedUser.user?.id) {
      userService().updateLocale({
        userId: authenticatedUser.user?.id,
        updateUserLocaleRequest: { locale: toUserLocaleType(language) },
      });
    }
  };

  const handleClick = () => {
    localStorage.setItem("lang", language);
    changeLocaleFunction(language);
    document.documentElement.lang = language;
    updateLocale();
  };

  return (
    <div>
      <Button className={classes.button}>
        <Typography className={classes.text} onClick={handleClick}>
          {label}
        </Typography>
      </Button>
    </div>
  );
};

export default LanguageSwitch;
