import { defineMessages } from "react-intl";

export const messages = defineMessages({
  usernameLabel: {
    id: "usernameLoginFields.username.label",
    defaultMessage: "Username",
    description: "label for username",
  },
  passwordLabel: {
    id: "usernameLoginFields.password.label",
    defaultMessage: "Password",
    description: "Label for password",
  },
});
