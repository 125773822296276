import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import {
  ContentItemResponse,
  CustomerUserAgreementType,
} from "../../../generated/customercontent-api";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import DialogCloseButton from "../../Buttons/DialogCloseButton/DialogCloseButton";
import { customerContentService } from "../../../Providers/ServiceProvider/ServiceProvider";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const PrivacyPolicyDialog = (props: Props) => {
  const theme = useTheme();
  const customerId = useCustomerId();
  const { onClose, isOpen } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [
    contentItemResponse,
    setContentItemResponse,
  ] = useState<ContentItemResponse>();

  const loadPrivacyPolicy = () => {
    if (customerId) {
      customerContentService()
        .getCustomerUserAgreementByType({
          customerId,
          agreementType: CustomerUserAgreementType.PrivacyPolicy,
        })
        .then(setContentItemResponse);
    }
  };

  useEffect(() => {
    if (isOpen) {
      loadPrivacyPolicy();
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} fullScreen={fullScreen}>
      <DialogCloseButton onClose={onClose} />
      <DialogTitle>{contentItemResponse?.title}</DialogTitle>
      <ReactQuill
        readOnly
        theme="snow"
        modules={{ toolbar: false }}
        value={
          contentItemResponse
            ? DOMPurify.sanitize(contentItemResponse?.contents)
            : undefined
        }
      />
    </Dialog>
  );
};

export default PrivacyPolicyDialog;
