import * as Yup from "yup";
import YupPassword from "yup-password";

YupPassword(Yup);

// text string is used as a key to fetch intl message
const UpdatePasswordValidationSchema = () => {
  return Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "newPasswordTooShort")
      .max(25, "newPasswordTooLong")
      .required("newPasswordRequired")
      .minNumbers(1, "newPasswordAtLeastOneNumber")
      .minLowercase(1, "newPasswordAtLeastOneLowercase")
      .minUppercase(1, "newPasswordAtLeastOneUppercase"),
    currentPassword: Yup.string().required("currentPasswordRequired"),
  });
};

export default UpdatePasswordValidationSchema;
