import { defineMessages } from "react-intl";

export const messages = defineMessages({
  usernameLabel: {
    id: "updateUsernameFields.username.label",
    defaultMessage: "New username",
    description: "Label for field new username",
  },
  passwordLabel: {
    id: "updateUsernameFields.password.label",
    defaultMessage: "Password",
    description: "Label for field password",
  },
});
