import { defineMessages } from "react-intl";

export const messages = defineMessages({
  info: {
    id: "login.username.info.text",
    defaultMessage:
      "If you have forgotten your username or password - please contact the {customerName}",
    description: "Info text",
  },
  usernameButton: {
    id: "login.username.button.label",
    defaultMessage: "Login - Username",
    description: "Label for button to login with username",
  },
  submitButtonLabel: {
    id: "login.username.button.submit.label",
    defaultMessage: "Login",
    description: "Submit label for login",
  },
  cancelButtonLabel: {
    id: "login.username.button.cancel.label",
    defaultMessage: "Cancel",
    description: "Cancel label for login",
  },
  noPhoneNumberError: {
    id: "login.username.error.noPhoneNumber",
    defaultMessage: "No phone number available for the user",
    description:
      "Text shown in snack bar when login failed due to missing phone number",
  },
  noUserFoundError: {
    id: "login.username.error.noUserFound",
    defaultMessage:
      "Failed to login, check your username and password and try again",
    description:
      "Text shown in snack bar when login failed due to missing phone number",
  },
  generalError: {
    id: "login.username.error.general",
    defaultMessage: "Something went wrong",
    description:
      "Text shown in snack bar when login failed due to general error",
  },
});
