import React from "react";
import { MenuItem, TextField, useTheme } from "@material-ui/core";
import { FormikProps } from "formik";

export interface Item {
  label?: string;
  value: string;
}
interface Props {
  id: string;
  label: string;
  errorMsg?: string;
  disabledFields?: string[];
  formik: FormikProps<any>;
  items: Item[];
}

export default function SelectField(props: Props) {
  const { id, label, formik, errorMsg, disabledFields, items } = props;
  const { errors, touched } = formik;
  const theme = useTheme();

  return (
    <TextField
      id={id}
      name={id}
      label={label}
      InputLabelProps={{
        style: { color: theme.palette.text.secondary },
      }}
      value={formik.values[id]}
      onChange={formik.handleChange}
      onFocus={() => formik.setFieldTouched(id, false)}
      error={!!(errors[id] && touched[id])}
      helperText={touched[id] && errors[id] ? errorMsg : null}
      variant="outlined"
      fullWidth
      select
      disabled={disabledFields?.includes(id)}
    >
      {items.map((item) => (
        <MenuItem value={item.value}>
          {item.label ? item.label : item.value}
        </MenuItem>
      ))}
    </TextField>
  );
}
