import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./ParticipantList.style";
import { MeetingParticipant } from "../../../../Models/Meeting";
import ProfilePicture from "../../../ProfilePicture/ProfilePicture";
import { displayUser, formatPersonNumber } from "../../../../Utils/User";
import { hasFeatureEnabled } from "../../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";
import { useCustomerFeatures } from "../../../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  participants?: MeetingParticipant[];
}

const ParticipantList = (props: Props) => {
  const { participants } = props;
  const classes = useStyles();
  const customerFeatures = useCustomerFeatures();
  const doFormatPersonNumber = hasFeatureEnabled(
    CustomerFeatureType.UsesPersonNumber,
    customerFeatures
  );

  if (!participants) {
    return null;
  }

  return (
    <List aria-label="list of all participants">
      {participants.map((p) => (
        <ListItem button className={classes.listItem}>
          <ListItemAvatar>
            <ProfilePicture firstName={p.firstName} lastName={p.lastName} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <span>
                {displayUser(doFormatPersonNumber, p, true)}
                <p style={{ padding: 0, margin: 0 }}>
                  <Typography variant="body2">
                    {p.personNumber
                      ? formatPersonNumber(doFormatPersonNumber, p.personNumber)
                      : p.workTitle}
                  </Typography>
                </p>
              </span>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ParticipantList;
