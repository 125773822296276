import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  customerNameRequired: {
    id: "validation.customerForm.customerNameRequired",
    defaultMessage: "Customer name is required",
    description: "Text to display when customer name is not filled",
  },
  customerNameTooLong: {
    id: "validation.customerForm.customerNameTooLong",
    defaultMessage: "Customer name can not be longer than 50 characters",
    description:
      "Text to display when user attempts to enter a customer name that is longer than 50 characters",
  },
  domainRequired: {
    id: "validation.customerForm.domainRequired",
    defaultMessage: "Domain name is required",
    description: "Text to display when domain name is not filled",
  },
  domainTooLong: {
    id: "validation.customerForm.domainTooLong",
    defaultMessage: "Domain name can not be longer than 50 characters",
    description:
      "Text to display when user attempts to enter a domain name that is longer than 50 characters",
  },
  organizationNumberTooLong: {
    id: "validation.customerForm.organizationNumberTooLong",
    defaultMessage: "Organization number can not be longer than 20 characters",
    description:
      "Text to display when user attempts to enter a organization number that is longer than 20 characters",
  },
});
