import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";

import { useStyles } from "./CustomFooter.style";
import { messages } from "./CustomFooter.messages";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import PrivacyPolicyDialog from "../Dialogs/PrivacyPolicyDialog/PrivacyPolicyDialog";
import ConsentAgreementDialog from "../Dialogs/ConsentAgreementDialog/ConsentAgreementDialog";
import { RoutePath } from "../Routes/RoutePath";
import ContactDetails from "./Components/ContactDetails/ContactDetails";
import { useDebugConsole } from "../DebugConsole/DebugConsole";

interface Props {
  changeLocaleFunction: (newLocale: string) => void;
}

const CustomFooter = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { changeLocaleFunction } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const { isEnabled, setIsEnabled } = useDebugConsole();
  const [
    isPrivacyPolicyDialogOpen,
    setIsPrivacyPolicyDialogOpen,
  ] = useState<boolean>(false);
  const [
    isConsentAgreementDialogOpen,
    setIsConsentAgreementDialogOpen,
  ] = useState<boolean>(false);

  const ConsentAndPrivacyPolicy = () => {
    if (authenticatedUser.isLoggedIn) {
      return (
        <>
          <Typography className={classes.buttonText}>
            <Button
              onClick={() => {
                history.push(`${RoutePath.ACCOUNT}?tab=3`);
                if (location.pathname === RoutePath.ACCOUNT) {
                  window.location.reload();
                }
              }}
              color="inherit"
              className={classes.button}
            >
              {intl.formatMessage(messages.privacyPolicy)}
            </Button>
          </Typography>
          <Typography className={classes.buttonText} color="inherit">
            <Button
              onClick={() => {
                history.push(`${RoutePath.ACCOUNT}?tab=2`);
                if (location.pathname === RoutePath.ACCOUNT) {
                  window.location.reload();
                }
              }}
              color="inherit"
              className={classes.button}
            >
              {intl.formatMessage(messages.consent)}
            </Button>
          </Typography>
        </>
      );
    }

    return (
      <>
        <PrivacyPolicyDialog
          isOpen={isPrivacyPolicyDialogOpen}
          onClose={() => setIsPrivacyPolicyDialogOpen(false)}
        />
        <ConsentAgreementDialog
          isOpen={isConsentAgreementDialogOpen}
          onClose={() => setIsConsentAgreementDialogOpen(false)}
        />
        <div>
          <Button
            className={classes.button}
            onClick={() => setIsPrivacyPolicyDialogOpen(true)}
          >
            <Typography className={classes.buttonText}>
              {intl.formatMessage(messages.privacyPolicy)}
            </Typography>
          </Button>
        </div>
        <div>
          <Button
            className={classes.button}
            onClick={() => setIsConsentAgreementDialogOpen(true)}
          >
            <Typography className={classes.buttonText} color="inherit">
              {intl.formatMessage(messages.consent)}
            </Typography>
          </Button>
        </div>
      </>
    );
  };

  if (location.pathname === RoutePath.SESSION_VIDEO) {
    return null;
  }

  return (
    <footer className={classes.footer}>
      <Container className={classes.content}>
        <Grid container>
          <Grid item md={4} xs={12} className={classes.gridItem}>
            <Typography className={classes.titleText} variant="overline">
              {intl.formatMessage(messages.general)}
            </Typography>
            <ConsentAndPrivacyPolicy />
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
            className={classes.gridItem}
            style={{ float: "left" }}
          >
            <ContactDetails />
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
            className={classes.gridItem}
            style={{ float: "left" }}
          >
            <Typography className={classes.titleText} variant="overline">
              {intl.formatMessage(messages.languageTitle)}
            </Typography>
            <LanguageSwitch
              language="sv"
              label={intl.formatMessage(messages.swedish)}
              changeLocaleFunction={changeLocaleFunction}
            />
            <LanguageSwitch
              language="en"
              label={intl.formatMessage(messages.english)}
              changeLocaleFunction={changeLocaleFunction}
            />
            <LanguageSwitch
              language="fr"
              label={intl.formatMessage(messages.french)}
              changeLocaleFunction={changeLocaleFunction}
            />
          </Grid>

          <Grid item xs={12} className={classes.versionText}>
            <Typography
              className={classes.text}
              variant="caption"
              onClick={() => setIsEnabled(!isEnabled)}
            >
              {intl.formatMessage(messages.version) +
                process.env.REACT_APP_VERSION}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default CustomFooter;
