import React from "react";
import { Container, Grid, Paper, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { NumberParam, useQueryParam } from "use-query-params";
import { useStyles } from "./Account.style";
import AccountSettingsForm from "./Components/AccountSettingsForm/AccountSettingsForm";
import ProfilePicture from "../../Components/ProfilePicture/ProfilePicture";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import Tabs, { TabConfig } from "../../Components/Tabs/Tabs";
import ConsentSettings from "./Components/ConsentSettings/ConsentSettings";
import { messages } from "./Account.messages";
import PrivacyPolicySettings from "./Components/PrivacyPolicySettings/PrivacyPolicySettings";
import UserNamePassword from "./Components/UserNamePassword/UserNamePassword";

const Account = () => {
  const intl = useIntl();
  const [tabIndex] = useQueryParam("tab", NumberParam);
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();

  if (!authenticatedUser.user) {
    return null;
  }

  const tabConfig: TabConfig[] = [
    {
      title: intl.formatMessage(messages.generalTabLabel),
      component: (
        <div className={classes.middlePaper}>
          <AccountSettingsForm user={authenticatedUser.user} />
        </div>
      ),
    },
    {
      title: intl.formatMessage(messages.usernameAndPasswordTabLabel),
      component: (
        <div className={classes.middlePaper}>
          <UserNamePassword user={authenticatedUser.user} />
        </div>
      ),
    },
    {
      title: intl.formatMessage(messages.consentTabLabel),
      component: (
        <div className={classes.middlePaper}>
          <ConsentSettings />
        </div>
      ),
    },
    {
      title: intl.formatMessage(messages.privacyPolicyTabLabel),
      component: (
        <div className={classes.middlePaper}>
          <PrivacyPolicySettings />
        </div>
      ),
    },
  ];

  return (
    <>
      <Grid container className={classes.root} spacing={2} justify="center">
        <Grid item xs={12} md={3}>
          <Paper className={classes.leftPaper}>
            <Container style={{ marginBottom: "20px" }}>
              <ProfilePicture size="large" center />
            </Container>
            <Typography variant="h5">
              {`${authenticatedUser.user.firstName} ${authenticatedUser.user.lastName}`}
            </Typography>
            {authenticatedUser.user.workTitle && (
              <Typography variant="h6">
                {authenticatedUser.user.workTitle}
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Tabs
            tabConfigs={tabConfig}
            defaultTab={tabIndex === null ? 0 : tabIndex}
            showTabs
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Account;
