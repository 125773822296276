import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
import {
  FormControlLabel,
  Switch,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { useCustomerId } from "../../../../../Providers/CustomerProvider/CustomerProvider";
import { messages as dialogMessages } from "../../ModifyUser.messages";
import { messages } from "./Password.messages";
import { userService } from "../../../../../Providers/ServiceProvider/ServiceProvider";
import { useStyles } from "./Password.style";
import { User } from "../../../../../Models/User";

interface Props {
  user: User;
  onClose: () => void;
  onChange: () => void;
}

const Password = (props: Props) => {
  const { user, onClose, onChange } = props;
  const intl = useIntl();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const customerId = useCustomerId();
  const [isActivated, setIsActivated] = useState(false);

  const handleSubmit = () => {
    if (customerId && user) {
      userService()
        .generatePassword({
          customerId,
          generatePasswordRequest: {
            userId: user.id,
          },
        })
        .then(() => {
          enqueueSnackbar(intl.formatMessage(messages.success), {
            variant: "success",
          });
        })
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(dialogMessages.generalError), {
            variant: "error",
          })
        );
    }
    onClose();
  };

  const handleActivate = () => {
    if (customerId && user) {
      userService()
        .activatePassword({
          customerId,
          userId: user.id,
        })
        .then(() => {
          setIsActivated(true);
        })
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(dialogMessages.generalError), {
            variant: "error",
          })
        );
    }
  };

  const handleDeactivate = () => {
    if (customerId && user) {
      userService()
        .deactivatePassword({
          customerId,
          userId: user.id,
        })
        .then(() => setIsActivated(false))
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(dialogMessages.generalError), {
            variant: "error",
          })
        );
    }
  };

  useEffect(() => {
    setIsActivated(user.hasActivatedPassword);
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onChange();
  }, [isActivated]); // eslint-disable-line react-hooks/exhaustive-deps

  const Activated = () => {
    return (
      <>
        <FormControlLabel
          control={
            <Switch
              size="medium"
              checked={isActivated}
              onClick={handleDeactivate}
            />
          }
          label={intl.formatMessage(messages.deactivateSwitchLabel)}
        />
        {user.mobilePhoneNumber && (
          <Typography variant="body2">
            {intl.formatMessage(
              user.hasPassword
                ? messages.description
                : messages.descriptionFirstTime
            )}
          </Typography>
        )}
        {!user.mobilePhoneNumber && (
          <Alert severity="warning">
            {intl.formatMessage(messages.warningMessage)}
          </Alert>
        )}
        {user.mobilePhoneNumber && (
          <div className={classes.container}>
            <MuiTextField
              id="phone"
              name="phone"
              size="small"
              value={user.mobilePhoneNumber}
              variant="outlined"
              disabled
            />
            <Button
              className={classes.redButton}
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
            >
              {intl.formatMessage(messages.submitButtonLabel)}
            </Button>
          </div>
        )}
      </>
    );
  };

  const Deactivated = () => {
    return (
      <>
        <Alert severity="info">
          {intl.formatMessage(messages.infoMessage)}
        </Alert>
        <div className={classes.container}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleActivate()}
          >
            {intl.formatMessage(messages.activateButtonLabel)}
          </Button>
        </div>
      </>
    );
  };

  return (
    <form>
      <DialogContent>
        {isActivated ? <Activated /> : <Deactivated />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.formatMessage(dialogMessages.cancelButtonLabel)}
        </Button>
      </DialogActions>
    </form>
  );
};

export default Password;
