import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      alignSelf: "center",
    },
    button: {
      margin: "20px 0",
    },
  })
);
