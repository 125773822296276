import { Grid, ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { messages } from "./CustomerListItem.messages";
import { CustomerResponse } from "../../../../generated/customer-api";
import { useStyles } from "./CustomerListItem.style";
import StatusChip from "../../../StatusChip/StatusChip";
import { getFormattedDate } from "../../../../Utils/Meeting";

export interface Props {
  customer: CustomerResponse;
  onItemClick?: (customerId: string) => void;
}

export default function CustomerListItem(props: Props) {
  const { customer, onItemClick } = props;
  const classes = useStyles(customer.isAdminPortal)();
  const intl = useIntl();

  return (
    <ListItem
      button
      divider
      key={customer.id}
      className={onItemClick && classes.listItem}
      onClick={() => onItemClick && onItemClick(customer.id)}
    >
      <Grid container spacing={0} className={classes.customerListGridContainer}>
        <Grid item xs={12} md={2}>
          <ListItemText
            primary={customer.customerName}
            secondary={customer.domain}
            primaryTypographyProps={{
              className: classes.customerListItemName,
            }}
            secondaryTypographyProps={{
              className: classes.customerListItemDomain,
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <ListItemText
            primary={intl.formatMessage(messages.customDomainLabel)}
            secondary={
              customer.isCustomDomain
                ? intl.formatMessage(messages.customDomainTrueLabel)
                : intl.formatMessage(messages.customDomainFalseLabel)
            }
            primaryTypographyProps={{
              variant: "caption",
              className: classes.customerListItemLabel,
            }}
            secondaryTypographyProps={{
              className: classes.customerListItemValue,
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <ListItemText
            primary={intl.formatMessage(messages.orgNumberLabel)}
            secondary={customer.organizationNumber}
            primaryTypographyProps={{
              variant: "caption",
              className: classes.customerListItemLabel,
            }}
            secondaryTypographyProps={{
              className: classes.customerListItemValue,
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <ListItemText
            primary={intl.formatMessage(messages.marketLabel)}
            secondary={customer.market}
            primaryTypographyProps={{
              variant: "caption",
              className: classes.customerListItemLabel,
            }}
            secondaryTypographyProps={{
              className: classes.customerListItemValue,
            }}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <ListItemText
            primary={intl.formatMessage(messages.defaultLocaleLabel)}
            secondary={customer.defaultLocale}
            primaryTypographyProps={{
              variant: "caption",
              className: classes.customerListItemLabel,
            }}
            secondaryTypographyProps={{
              className: classes.customerListItemValue,
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <ListItemText
            primary={intl.formatMessage(messages.createdLabel)}
            secondary={getFormattedDate(customer.created)}
            primaryTypographyProps={{
              variant: "caption",
              className: classes.customerListItemLabel,
            }}
            secondaryTypographyProps={{
              className: classes.customerListItemValue,
            }}
          />
        </Grid>
        <Grid item xs={12} md={1} className={classes.statusGrid}>
          <StatusChip status={customer.status} />
        </Grid>
      </Grid>
    </ListItem>
  );
}
