import React from "react";
import { Button, Chip, Typography } from "@material-ui/core";
import linkifyHtml from "linkify-html";
import CheckIcon from "@material-ui/icons/Check";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import { useStyles } from "./MessageChip.style";
import { MessageResponse } from "../../../../../../generated/message-api";
import ProfilePicture from "../../../../../../Components/ProfilePicture/ProfilePicture";
import { useAuthenticatedUser } from "../../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { UserRoleType } from "../../../../../../generated/user-api";
import { displayUser, isAuthenticatedUser } from "../../../../../../Utils/User";
import {
  getFormattedDate,
  getFormattedTimespan,
} from "../../../../../../Utils/Meeting";
import DownloadableFile from "../DownloadableFile/DownloadableFile";
import { useCustomerFeatures } from "../../../../../../Providers/CustomerProvider/CustomerProvider";
import { hasFeatureEnabled } from "../../../../../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../../../generated/customersettings-api";

interface Props {
  messageResponse?: MessageResponse;
  distinctDate?: boolean;
  onDeleted?: () => void;
}

const SHOW_AVATAR_AND_NAME = false;

const MessageChip = (props: Props) => {
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const { messageResponse, distinctDate, onDeleted } = props;
  const customerFeatures = useCustomerFeatures();
  const doFormatPersonNumber = hasFeatureEnabled(
    CustomerFeatureType.UsesPersonNumber,
    customerFeatures
  );

  if (!messageResponse) {
    return null;
  }

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {distinctDate && (
        <div className={classes.dateContainer}>
          <div className={classes.divSpliter} />
          <Button
            size="small"
            color="primary"
            variant="contained"
            disabled
            className={classes.dateFormat}
          >
            {getFormattedDate(messageResponse.sentOn)}
          </Button>
          <div className={classes.divSpliter} />
        </div>
      )}
      {SHOW_AVATAR_AND_NAME && (
        <Chip
          className={classes.avatar}
          label={displayUser(doFormatPersonNumber, messageResponse.sender)}
          avatar={
            <ProfilePicture
              firstName={messageResponse.sender.firstName}
              lastName={messageResponse.sender.lastName}
            />
          }
        />
      )}
      <div
        className={
          isAuthenticatedUser(messageResponse.sender.id, authenticatedUser.user)
            ? classes.chipReverse
            : classes.chip
        }
      >
        <div
          className={
            isAuthenticatedUser(
              messageResponse.sender.id,
              authenticatedUser.user
            )
              ? classes.chipContainerSecondary
              : classes.chipContainerDefault
          }
        >
          {messageResponse.attachment ? (
            <DownloadableFile
              onDeleted={onDeleted}
              attachment={messageResponse.attachment}
              createdBy={messageResponse.sender.id}
            />
          ) : (
            <>
              <ReactQuill
                theme="bubble"
                readOnly
                modules={{ toolbar: false }}
                value={linkifyHtml(
                  DOMPurify.sanitize(messageResponse.contents)
                )}
                style={{ maxWidth: "100%" }}
              />
              {authenticatedUser.user?.userRole === UserRoleType.Staff &&
                messageResponse.status === "MARKED_AS_READ" &&
                isAuthenticatedUser(
                  messageResponse.sender.id,
                  authenticatedUser.user
                ) && <CheckIcon className={classes.checksecond} />}
              {authenticatedUser.user?.userRole === UserRoleType.Staff &&
                isAuthenticatedUser(
                  messageResponse.sender.id,
                  authenticatedUser.user
                ) && <CheckIcon className={classes.checkyes} />}
            </>
          )}
        </div>
        <div className={classes.timeFormat}>
          <Typography component="small" variant="caption">
            {getFormattedTimespan(messageResponse.sentOn)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default MessageChip;
