import React from "react";
import { FormikProps } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { Grid } from "@material-ui/core";
import { IntlShape, useIntl } from "react-intl";
import { messages } from "./UpdatePasswordFields.messages";
import { messages as errorMessages } from "./Components/Validation/Validation.messages";
import PasswordField from "../PasswordField/PasswordField";

export interface UpdatePasswordFormikValues {
  newPassword: string;
  currentPassword: string;
}

interface Props {
  formik: FormikProps<UpdatePasswordFormikValues>;
}

const getErrorMessage = (intl: IntlShape, error?: any) => {
  if (!error || !errorMessages[error]) return "";
  return intl.formatMessage(errorMessages[error]);
};

const UpdatePasswordFields = (props: Props) => {
  const intl = useIntl();
  const { formik } = props;
  const { errors } = formik;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PasswordField
          id="newPassword"
          formik={formik}
          label={intl.formatMessage(messages.newPasswordLabel)}
          errorMsg={getErrorMessage(intl, errors.newPassword?.toString())}
        />
      </Grid>
      <Grid item xs={12}>
        <PasswordField
          id="currentPassword"
          formik={formik}
          label={intl.formatMessage(messages.currentPasswordLabel)}
          errorMsg={getErrorMessage(intl, errors.currentPassword?.toString())}
        />
      </Grid>
    </Grid>
  );
};

export default UpdatePasswordFields;
