/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserAuthorityType,
    UserAuthorityTypeFromJSON,
    UserAuthorityTypeFromJSONTyped,
    UserAuthorityTypeToJSON,
    UserLocaleType,
    UserLocaleTypeFromJSON,
    UserLocaleTypeFromJSONTyped,
    UserLocaleTypeToJSON,
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * The UUID of this user
     * @type {string}
     * @memberof UserResponse
     */
    id: string;
    /**
     * true if user has person number.
     * @type {boolean}
     * @memberof UserResponse
     */
    hasPersonNumber: boolean;
    /**
     * true if user has HSA_ID.
     * @type {boolean}
     * @memberof UserResponse
     */
    hasHsaId: boolean;
    /**
     * true if user has password.
     * @type {boolean}
     * @memberof UserResponse
     */
    hasPassword: boolean;
    /**
     * true if user has password login activated.
     * @type {boolean}
     * @memberof UserResponse
     */
    hasActivatedPassword: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    workTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    mobilePhoneNumber?: string;
    /**
     * 
     * @type {UserLocaleType}
     * @memberof UserResponse
     */
    locale: UserLocaleType;
    /**
     * 
     * @type {UserStatusType}
     * @memberof UserResponse
     */
    userStatus: UserStatusType;
    /**
     * 
     * @type {UserRoleType}
     * @memberof UserResponse
     */
    userRole: UserRoleType;
    /**
     * 
     * @type {Array<UserAuthorityType>}
     * @memberof UserResponse
     */
    userAuthorities?: Array<UserAuthorityType>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    additionalInformation?: string;
    /**
     * Date and time this user was created
     * @type {Date}
     * @memberof UserResponse
     */
    createdOn?: Date;
    /**
     * Date and time this user was last updated
     * @type {Date}
     * @memberof UserResponse
     */
    updatedOn?: Date;
}

export function UserResponseFromJSON(json: any): UserResponse {
    return UserResponseFromJSONTyped(json, false);
}

export function UserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'hasPersonNumber': json['hasPersonNumber'],
        'hasHsaId': json['hasHsaId'],
        'hasPassword': json['hasPassword'],
        'hasActivatedPassword': json['hasActivatedPassword'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'workTitle': !exists(json, 'workTitle') ? undefined : json['workTitle'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mobilePhoneNumber': !exists(json, 'mobilePhoneNumber') ? undefined : json['mobilePhoneNumber'],
        'locale': UserLocaleTypeFromJSON(json['locale']),
        'userStatus': UserStatusTypeFromJSON(json['userStatus']),
        'userRole': UserRoleTypeFromJSON(json['userRole']),
        'userAuthorities': !exists(json, 'userAuthorities') ? undefined : ((json['userAuthorities'] as Array<any>).map(UserAuthorityTypeFromJSON)),
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
        'createdOn': !exists(json, 'createdOn') ? undefined : (new Date(json['createdOn'])),
        'updatedOn': !exists(json, 'updatedOn') ? undefined : (new Date(json['updatedOn'])),
    };
}

export function UserResponseToJSON(value?: UserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'hasPersonNumber': value.hasPersonNumber,
        'hasHsaId': value.hasHsaId,
        'hasPassword': value.hasPassword,
        'hasActivatedPassword': value.hasActivatedPassword,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'username': value.username,
        'workTitle': value.workTitle,
        'email': value.email,
        'mobilePhoneNumber': value.mobilePhoneNumber,
        'locale': UserLocaleTypeToJSON(value.locale),
        'userStatus': UserStatusTypeToJSON(value.userStatus),
        'userRole': UserRoleTypeToJSON(value.userRole),
        'userAuthorities': value.userAuthorities === undefined ? undefined : ((value.userAuthorities as Array<any>).map(UserAuthorityTypeToJSON)),
        'additionalInformation': value.additionalInformation,
        'createdOn': value.createdOn === undefined ? undefined : (value.createdOn.toISOString()),
        'updatedOn': value.updatedOn === undefined ? undefined : (value.updatedOn.toISOString()),
    };
}


