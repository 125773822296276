import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Alert } from "@material-ui/lab";
import { FormikProps } from "formik";
import { useIntl } from "react-intl";
import { UserResponse } from "../../../../../generated/user-api";
import { getUserId } from "../../../../../Utils/User";
import { meetingService } from "../../../../../Providers/ServiceProvider/ServiceProvider";
import { useCustomerId } from "../../../../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { NotAvailableUser } from "../../../../Fields/UserSelectField/UserSelectField";
import { messages } from "./ParticipantNotAvailableList.messages";

interface Props {
  formik: FormikProps<any>;
  excludedMeetingId?: string;
}

const ParticipantNotAvailableList = (props: Props) => {
  const intl = useIntl();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const { formik, excludedMeetingId } = props;
  const [unavailableParticipants, setUnavailableParticipants] = useState<
    NotAvailableUser[]
  >([]);

  const checkAvailabilityForUser = async (
    user: UserResponse
  ): Promise<NotAvailableUser | undefined> => {
    let result;
    if (customerId) {
      const userId = getUserId(user);
      if (userId !== authenticatedUser.user?.id) {
        await meetingService()
          .checkIfUserIsAvailable({
            customerId,
            userId,
            from: formik.values.startDateTime,
            duration: formik.values.duration,
            excludedMeetingId,
          })
          .then((res) => {
            if (!res.isAvailable) {
              result = {
                id: userId,
                name: `${user.firstName} ${user.lastName}`,
              };
            }
          });
      }
    }
    return result;
  };

  const checkAvailabilityForUsers = async (): Promise<NotAvailableUser[]> => {
    const resultArray: NotAvailableUser[] = [];
    const participants = [
      ...formik.values.staffParticipants,
      ...formik.values.externalParticipants,
      ...formik.values.participants,
    ];
    await participants.forEach((participant: UserResponse) => {
      checkAvailabilityForUser(participant).then((result) => {
        if (result) {
          resultArray.push(result);
        }
        setUnavailableParticipants([...resultArray]);
      });
    });
    return resultArray;
  };

  useEffect(() => {
    checkAvailabilityForUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.staffParticipants,
    formik.values.externalParticipants,
    formik.values.participants,
    formik.values.startDateTime,
    formik.values.duration,
  ]);

  return (
    <>
      {unavailableParticipants
        ?.filter((user) => user)
        .map((unavailableParticipant) => (
          <>
            <Alert severity="warning">
              {`${unavailableParticipant.name} ${intl.formatMessage(
                messages.alreadyHasLabel
              )}`}
            </Alert>
          </>
        ))}
    </>
  );
};

export default ParticipantNotAvailableList;
