import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  useTheme,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogContent from "@material-ui/core/DialogContent";
import { useFormik } from "formik";
import {
  CreateCustomerRequest,
  CustomerRequest,
  CustomerResponse,
  DefaultLocale,
  Market,
  UpdateCustomerRequest,
} from "../../../generated/customer-api";
import { messages } from "./CreateOrUpdateCustomerDialog.messages";
import ButtonLoadingWrapper from "../../Buttons/ButtonLoadingWrapper/ButtonLoadingWrapper";
import { customerService } from "../../../Providers/ServiceProvider/ServiceProvider";
import CustomerForm from "../../Forms/CustomerForm/CustomerForm";
import CustomerFormValidationSchema from "../../Forms/CustomerForm/Validation/CustomerFormValidationSchema";

export enum Variant {
  CREATE,
  UPDATE,
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  initialCustomerValues?: CustomerResponse;
  variant: Variant;
}

const CreateOrUpdateCustomerDialog = (props: Props) => {
  const { isOpen, onClose, initialCustomerValues, variant } = props;
  const intl = useIntl();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [isCustomDomain, setIsCustomDomain] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues: CustomerRequest = {
    customerName: initialCustomerValues?.customerName || "",
    domain: initialCustomerValues?.domain || "",
    organizationNumber: initialCustomerValues?.organizationNumber || "",
    isCustomDomain: initialCustomerValues?.isCustomDomain || isCustomDomain,
    market: initialCustomerValues?.market || Market.Sweden,
    defaultLocale:
      initialCustomerValues?.defaultLocale || DefaultLocale.Swedish,
  };

  const createCustomer = (values: CustomerRequest) => {
    return customerService().createCustomer({
      body: values,
    } as CreateCustomerRequest);
  };

  const updateCustomer = (values: CustomerRequest) => {
    return customerService().updateCustomer({
      id: initialCustomerValues?.id,
      body: values,
    } as UpdateCustomerRequest);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CustomerFormValidationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      (variant === Variant.UPDATE
        ? updateCustomer(values)
        : createCustomer(values)
      )
        .then(() => {
          if (variant === Variant.CREATE) {
            formik.resetForm();
            enqueueSnackbar(intl.formatMessage(messages.successCreate), {
              variant: "success",
            });
          } else {
            enqueueSnackbar(intl.formatMessage(messages.successUpdate), {
              variant: "success",
            });
          }
          onClose();
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          });
        })
        .finally(() => setIsLoading(false));
    },
  });

  useEffect(() => {
    formik.values.isCustomDomain = isCustomDomain;
  }, [isCustomDomain]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {variant === Variant.UPDATE
          ? intl.formatMessage(messages.dialogTitleUpdate)
          : intl.formatMessage(messages.dialogTitleCreate)}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <CustomerForm
            formik={formik}
            isCustomDomain={isCustomDomain}
            onIsCustomDomain={setIsCustomDomain}
          />
        </DialogContent>
        <DialogActions>
          <ButtonLoadingWrapper isLoading={isLoading}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isLoading}
            >
              {variant === Variant.UPDATE
                ? intl.formatMessage(messages.submitButtonUpdateLabel)
                : intl.formatMessage(messages.submitButtonCreateLabel)}
            </Button>
          </ButtonLoadingWrapper>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage(messages.cancelButtonLabel)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateOrUpdateCustomerDialog;
