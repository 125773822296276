import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  titleRequired: {
    id: "validation.meetingTemplateForm.titleRequired",
    defaultMessage: "Booking template title is required",
    description:
      "Text to display when user attempts to enter empty title for booking template",
  },
  titleTooLong: {
    id: "validation.meetingTemplateForm.titleTooLong",
    defaultMessage:
      "Booking template title can not be longer than 50 characters",
    description:
      "Text to display when user attempts to enter a booking template title that is longer than 50 characters",
  },
  durationRequired: {
    id: "validation.meetingTemplateForm.durationRequired",
    defaultMessage: "Duration for booking template is required",
    description:
      "Text to display when user does not enter duration for booking template",
  },
  durationTooShort: {
    id: "validation.meetingTemplateForm.durationTooShort",
    defaultMessage: "Booking template time can not be shorter than 1 minute",
    description:
      "Text to display when user attempts to enter a booking template title that is too short",
  },
});
