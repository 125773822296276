import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    errorMessage: {
      paddingTop: theme.spacing(0.5),
      paddingLeft: theme.spacing(1.5),
      color: theme.palette.error.main,
    },
  })
);
