import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { MeetingResponse } from "../../../../generated/meeting-api";
import {
  getFormattedDate,
  getFormattedTimespan,
  getParticipantsAfterRemoval,
} from "../../../../Utils/Meeting";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useStyles } from "./ParticipantCard.style";
import AvatarGroup from "../../../../Components/AvatarGroup/AvatarGroup";
import CreateMeetingButton from "../../../../Components/Buttons/CreateMeetingButton/CreateMeetingButton";
import ProfilePicture from "../../../../Components/ProfilePicture/ProfilePicture";
import FeatureController, {
  hasFeatureEnabled,
} from "../../../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";
import PermissionController from "../../../../Components/PermissionController/PermissionController";
import {
  UserAuthorityType,
  UserStatusType,
} from "../../../../generated/user-api";
import { displayUser } from "../../../../Utils/User";
import { messages } from "./ParticipantCard.messages";
import CreateMessageButton from "../../../../Components/Buttons/CreateMessageButton/CreateMessageButton";
import { Contact } from "../../../../Models/Contact";
import { contactToUser } from "../../../../Utils/ModelConverter";
import { useCustomerFeatures } from "../../../../Providers/CustomerProvider/CustomerProvider";

export enum Variant {
  PARTICIPANT_OVERVIEW,
  MEETINGS_OVERVIEW,
  MEETING_DETAILS,
}

interface Props {
  variant: Variant;
  contact?: Contact;
  meeting?: MeetingResponse;
}

const ParticipantCard = (props: Props) => {
  const { meeting, variant, contact } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const customerFeatures = useCustomerFeatures();
  const doFormatPersonNumber = hasFeatureEnabled(
    CustomerFeatureType.UsesPersonNumber,
    customerFeatures
  );

  const filteredParticipants = getParticipantsAfterRemoval(
    meeting?.participants || [],
    [authenticatedUser.user?.id || ""]
  );

  const formattedDateString = `${getFormattedDate(
    meeting?.startDateTime || new Date()
  )} (${getFormattedTimespan(
    meeting?.startDateTime || new Date(),
    meeting?.duration
  )})`;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3} justify="space-between">
        <Grid item md={9} xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              {meeting ? (
                <AvatarGroup
                  size="small"
                  max={5}
                  participants={filteredParticipants}
                />
              ) : (
                <ProfilePicture
                  size="small"
                  firstName={contact?.firstName || ""}
                  lastName={contact?.lastName || ""}
                />
              )}
            </Grid>
            <Grid item>
              <Typography className={classes.boldText} variant="h6">
                {variant === Variant.PARTICIPANT_OVERVIEW &&
                  intl.formatMessage(messages.personOverviewLabel)}
                {variant === Variant.MEETINGS_OVERVIEW &&
                  intl.formatMessage(messages.bookingHistoryLabel)}
                {variant === Variant.MEETING_DETAILS && meeting?.title}
              </Typography>
              <Typography variant="h6">
                {meeting && formattedDateString}
                {!meeting &&
                  contact &&
                  displayUser(
                    doFormatPersonNumber,
                    contactToUser(contact),
                    true
                  )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {variant === Variant.MEETINGS_OVERVIEW &&
          contact &&
          contact.userStatus === UserStatusType.Active && (
            <Grid item md={3} xs={12} className={classes.buttonGrid}>
              <FeatureController requiredFeature={CustomerFeatureType.Messages}>
                <Grid item>
                  <CreateMessageButton
                    className={classes.button}
                    variant="contained"
                    recipients={[contactToUser(contact)]}
                  />
                </Grid>
              </FeatureController>
              <PermissionController
                requiredAuthoritiesClient={[UserAuthorityType.BookMeeting]}
                requiredAuthoritiesStaff={[UserAuthorityType.BookMeeting]}
                requiredAuthoritiesExternal={[UserAuthorityType.BookMeeting]}
              >
                <Grid item>
                  <CreateMeetingButton
                    prePopulatedParticipants={[contactToUser(contact)]}
                  />
                </Grid>
              </PermissionController>
            </Grid>
          )}
      </Grid>
    </Paper>
  );
};

export default ParticipantCard;
