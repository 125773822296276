/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Customer default locale
 * @export
 * @enum {string}
 */
export enum DefaultLocale {
    Swedish = 'SWEDISH',
    English = 'ENGLISH',
    French = 'FRENCH'
}

export function DefaultLocaleFromJSON(json: any): DefaultLocale {
    return DefaultLocaleFromJSONTyped(json, false);
}

export function DefaultLocaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultLocale {
    return json as DefaultLocale;
}

export function DefaultLocaleToJSON(value?: DefaultLocale | null): any {
    return value as any;
}

