import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  newPasswordRequired: {
    id: "validation.updatePassword.newPasswordRequired",
    defaultMessage: "Password is required",
    description: "Text to display when user attempts to enter empty password",
  },
  newPasswordAtLeastOneNumber: {
    id: "validation.updatePassword.newPasswordAtLeastOneNumber",
    defaultMessage: "At least one number is required",
    description:
      "Text to display when user attempts to enter password without any numbers",
  },
  newPasswordAtLeastOneLowercase: {
    id: "validation.updatePassword.newPasswordAtLeastOneLowercase",
    defaultMessage: "At least one lowercase character is required",
    description:
      "Text to display when user attempts to enter password without any lowercase",
  },
  newPasswordAtLeastOneUppercase: {
    id: "validation.updatePassword.newPasswordAtLeastOneUppercase",
    defaultMessage: "At least one uppercase character is required",
    description:
      "Text to display when user attempts to enter password without any uppercase",
  },
  newPasswordTooLong: {
    id: "validation.updatePassword.newPasswordTooLong",
    defaultMessage: "Password can not be longer than 25 characters",
    description:
      "Text to display when user attempts to enter a password that is longer than 25 characters",
  },
  newPasswordTooShort: {
    id: "validation.updatePassword.newPasswordTooShort",
    defaultMessage: "Password can not be shorter than 8 characters",
    description:
      "Text to display when user attempts to enter a password that is shorter than 8 characters",
  },
  currentPasswordRequired: {
    id: "validation.updatePassword.currentPasswordRequired",
    defaultMessage: "Password is required",
    description: "Text to display when user attempts to enter empty password",
  },
});
