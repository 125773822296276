import { defineMessages } from "react-intl";

export const messages = defineMessages({
  searchInviteLabel: {
    id: "dialog.addOrCreateUser.field.searchQuery.invite.label",
    defaultMessage: "Email address",
    description: "label for email field",
  },
  searchCreatePersonNumberLabel: {
    id: "dialog.addOrCreateUser.field.searchQuery.create.personNumber.label",
    defaultMessage: "Personal identity number",
    description: "label for personnumber",
  },
  searchCreatePhoneLabel: {
    id: "dialog.addOrCreateUser.field.searchQuery.create.phone.label",
    defaultMessage: "Phone number",
    description: "label for phone",
  },
  searchCreateOrInvitePersonalNumberOrEmailLabel: {
    id:
      "dialog.addOrCreateUser.field.searchQuery.createOrInvite.personNumberOrEmail.label",
    defaultMessage: "Personal identity number or email address1",
    description: "label for personnumber or email field",
  },
  searchCreateOrInvitePhoneOrEmailLabel: {
    id:
      "dialog.addOrCreateUser.field.searchQuery.createOrInvite.phoneOrEmail.label",
    defaultMessage: "Phone number or email address1",
    description: "label for phone or email field",
  },
});
