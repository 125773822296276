import React from "react";
import { Typography } from "@material-ui/core";
import { MeetingParticipantResponse } from "../generated/meeting-api";
import { UserResponse } from "../generated/user-api";
import { User } from "../Models/User";
import { MeetingParticipant } from "../Models/Meeting";
import { MessageUser } from "../Models/Message";
import { TodoUser } from "../Models/Todo";
import { Contact } from "../Models/Contact";
import { AuthenticatedUser } from "../generated/authentication-api";
import { FileUserResponse } from "../generated/file-api";

export const formatPersonNumber = (
  doFormatPersonNumber: boolean,
  personNumber?: string
): string => {
  if (!personNumber) {
    return "";
  }
  if (personNumber === "Overifierad") {
    return personNumber;
  }
  return doFormatPersonNumber
    ? `${personNumber.slice(0, 8)}-${personNumber.slice(8)}`
    : personNumber;
};

export const formatName = (
  firstName?: string,
  lastName?: string,
  email?: string
): string => {
  if (!firstName && !lastName) {
    if (email) {
      return email;
    }
    return "";
  }
  if (firstName && !lastName) {
    return firstName;
  }
  if (!firstName && lastName) {
    return lastName;
  }
  return `${firstName} ${lastName}`;
};

export const displayUser = (
  doFormatPersonNumber: boolean,
  userToDisplay?: User | Contact | MeetingParticipant | MessageUser | TodoUser,
  nameOnly?: boolean
): string => {
  if (!userToDisplay) {
    return "";
  }

  if (nameOnly) {
    return formatName(
      userToDisplay.firstName,
      userToDisplay.lastName,
      userToDisplay.email
    );
  }

  if (!nameOnly && userToDisplay.personNumber) {
    return `${formatName(
      userToDisplay.firstName,
      userToDisplay.lastName,
      userToDisplay.email
    )} (${formatPersonNumber(
      doFormatPersonNumber,
      userToDisplay.personNumber
    )})`;
  }

  if (!nameOnly && userToDisplay.workTitle) {
    return `${formatName(
      userToDisplay.firstName,
      userToDisplay.lastName,
      userToDisplay.email
    )} (${userToDisplay.workTitle})`;
  }

  return formatName(
    userToDisplay.firstName,
    userToDisplay.lastName,
    userToDisplay.email
  );
};

export const displayAuthenticatedUser = (
  userToDisplay?: AuthenticatedUser
): string => {
  if (!userToDisplay) {
    return "";
  }

  return formatName(userToDisplay.firstName, userToDisplay.lastName, undefined);
};

export const displayUserComponent = (
  doFormatPersonNumber: boolean,
  userToDisplay?: User | MeetingParticipant | MessageUser,
  nameOnly?: boolean,
  showWorkTitle?: boolean
): any => {
  if (!userToDisplay) {
    return null;
  }
  if (nameOnly) {
    return (
      <span>{`${userToDisplay.firstName} ${userToDisplay.lastName}`}</span>
    );
  }
  return userToDisplay.personNumber ? (
    <span>
      {`${userToDisplay.firstName} ${userToDisplay.lastName}`}
      <p style={{ padding: 0, margin: 0 }}>
        <Typography variant="body2">
          {`(${formatPersonNumber(
            doFormatPersonNumber,
            userToDisplay.personNumber
          )})`}
        </Typography>
      </p>
    </span>
  ) : (
    <span>
      {`${userToDisplay.firstName} ${userToDisplay.lastName}`}
      <p style={{ padding: 0, margin: 0 }}>
        <Typography variant="body2">
          {`${(showWorkTitle && (userToDisplay.workTitle || "")) || ""}`}
        </Typography>
      </p>
    </span>
  );
};

export const isAuthenticatedUser = (
  userId?: string,
  authenticatedUser?: UserResponse
): boolean => {
  return userId === authenticatedUser?.id;
};

export const getUsersIds = (users: UserResponse[]): string[] => {
  return users?.map((u) => u.id);
};

export const getUserId = (
  user?: UserResponse | MeetingParticipantResponse
): string => {
  if (!user) {
    return "";
  }
  return ("id" in user && user?.id) || ("userId" in user && user?.userId) || "";
};

export const getUserInitials = (
  firstName?: string,
  lastName?: string
): string => {
  return `${firstName?.substr(0, 1) || ""} ${lastName?.substr(0, 1) || ""}`;
};

export const getFileUsersAfterRemoval = (
  users: FileUserResponse[],
  userIdsToRemove: string[]
): FileUserResponse[] => {
  return users.filter((user) => !userIdsToRemove.includes(user.id || ""));
};
