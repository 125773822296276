/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUsernameRequest
 */
export interface UpdateUsernameRequest {
    /**
     * new username
     * @type {string}
     * @memberof UpdateUsernameRequest
     */
    username: string;
    /**
     * Password
     * @type {string}
     * @memberof UpdateUsernameRequest
     */
    password: string;
}

export function UpdateUsernameRequestFromJSON(json: any): UpdateUsernameRequest {
    return UpdateUsernameRequestFromJSONTyped(json, false);
}

export function UpdateUsernameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUsernameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password': json['password'],
    };
}

export function UpdateUsernameRequestToJSON(value?: UpdateUsernameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
    };
}


