/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserAuthorityType,
    UserAuthorityTypeFromJSON,
    UserAuthorityTypeFromJSONTyped,
    UserAuthorityTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    workTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    mobilePhoneNumber?: string;
    /**
     * 
     * @type {Array<UserAuthorityType>}
     * @memberof UpdateUserRequest
     */
    userAuthorities?: Array<UserAuthorityType>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    additionalInformation?: string;
}

export function UpdateUserRequestFromJSON(json: any): UpdateUserRequest {
    return UpdateUserRequestFromJSONTyped(json, false);
}

export function UpdateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'workTitle': !exists(json, 'workTitle') ? undefined : json['workTitle'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mobilePhoneNumber': !exists(json, 'mobilePhoneNumber') ? undefined : json['mobilePhoneNumber'],
        'userAuthorities': !exists(json, 'userAuthorities') ? undefined : ((json['userAuthorities'] as Array<any>).map(UserAuthorityTypeFromJSON)),
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
    };
}

export function UpdateUserRequestToJSON(value?: UpdateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'workTitle': value.workTitle,
        'email': value.email,
        'mobilePhoneNumber': value.mobilePhoneNumber,
        'userAuthorities': value.userAuthorities === undefined ? undefined : ((value.userAuthorities as Array<any>).map(UserAuthorityTypeToJSON)),
        'additionalInformation': value.additionalInformation,
    };
}


