import React from "react";
import { IntlProvider } from "react-intl";
import Swedish from "./lang/sv.json";
import English from "./lang/en.json";
import French from "./lang/fr.json";

interface Props {
  children: React.ReactNode;
  locale: string;
}

const setLanguageBasedOnLocale = (locale: string) => {
  if (locale === "en") {
    return English;
  }
  if (locale === "sv") {
    return Swedish;
  }
  if (locale === "fr") {
    return French;
  }
  // Default language
  return Swedish;
};

const LanguageProvider = (props: Props) => {
  const { children, locale } = props;
  const lang = setLanguageBasedOnLocale(locale);

  return (
    <IntlProvider locale={locale} messages={lang}>
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
