import { defineMessages } from "react-intl";

export const messages = defineMessages({
  customerDialogNameFieldLabel: {
    id: "customerForm.name.label",
    defaultMessage: "Name",
    description: "Customer field name label",
  },
  customerDialogDomainFieldLabel: {
    id: "customerForm.domain.label",
    defaultMessage: "Domain",
    description: "Customer field domain label",
  },
  customerDialogOrgNumberFieldLabel: {
    id: "customerForm.orgnumber.label",
    defaultMessage: "Organization number",
    description: "Customer field organization number",
  },
  customerDialogMarketFieldLabel: {
    id: "customerForm.market.label",
    defaultMessage: "Market",
    description: "Customer field market label",
  },
  customerDialogDefaultLocaleFieldLabel: {
    id: "customerForm.defaultLocale.label",
    defaultMessage: "Language",
    description: "Customer field default locale label",
  },
  customerDialogCustomDomainLabel: {
    id: "customerForm.customDomain.label",
    defaultMessage: "Custom domain",
    description: "Customer field custom domain checkbox label",
  },
});
