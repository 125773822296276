import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ProfilePicture from "../../ProfilePicture/ProfilePicture";
import { AttendanceStatusType } from "../../../generated/meeting-api";
import { displayUser, formatPersonNumber } from "../../../Utils/User";
import { useStyles } from "./ParticipantList.style";
import { MeetingParticipant } from "../../../Models/Meeting";
import { hasFeatureEnabled } from "../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../generated/customersettings-api";
import { useCustomerFeatures } from "../../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  participants?: MeetingParticipant[];
}

const ParticipantList = (props: Props) => {
  const { participants } = props;
  const classes = useStyles();
  const customerFeatures = useCustomerFeatures();
  const doFormatPersonNumber = hasFeatureEnabled(
    CustomerFeatureType.UsesPersonNumber,
    customerFeatures
  );

  const getStatusIcon = (participant: MeetingParticipant) => {
    switch (participant.participantAttendance.status) {
      case AttendanceStatusType.Confirmed:
        return (
          <CheckCircleOutlineIcon
            className={classes.confirmed}
            fontSize="large"
          />
        );
      case AttendanceStatusType.Declined:
        return (
          <HighlightOffIcon className={classes.declined} fontSize="large" />
        );
      default:
        return (
          <HelpOutlineIcon className={classes.notConfirmed} fontSize="large" />
        );
    }
  };

  if (!participants) {
    return null;
  }

  return (
    <List component="nav" aria-label="list of all participants">
      {participants.map((p) => (
        <ListItem>
          <ListItemAvatar>
            <ProfilePicture firstName={p.firstName} lastName={p.lastName} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <span>
                {displayUser(doFormatPersonNumber, p, true)}
                <p style={{ padding: 0, margin: 0 }}>
                  <Typography variant="body2">
                    {p.personNumber
                      ? formatPersonNumber(doFormatPersonNumber, p.personNumber)
                      : p.workTitle}
                  </Typography>
                </p>
              </span>
            }
          />
          <ListItemIcon>{getStatusIcon(p)}</ListItemIcon>
        </ListItem>
      ))}
    </List>
  );
};

export default ParticipantList;
