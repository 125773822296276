import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { Button, Grid, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  useCustomerId,
  useCustomerIsAdminPortal,
} from "../../../../Providers/CustomerProvider/CustomerProvider";
import { authenticationService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import { messages } from "./BankIdLogin.messages";
import { useStyles } from "./BankIdLogin.style";
import {
  AuthenticationStartResponse,
  AuthType,
} from "../../../../generated/authentication-api";
import { emptyStringToUndefined } from "../../../../Utils/Format";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";
import FeatureController from "../../../../Components/FeatureController/FeatureController";

interface FormikValues {
  personNumber?: string;
}

interface Props {
  onClick: () => void;
  onCancel: () => void;
  onError: (errorMsg?: string) => void;
}

const BankIdLogin = (props: Props) => {
  const { onClick, onCancel, onError } = props;
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const isCustomerAdminPortal = useCustomerIsAdminPortal();
  const { enqueueSnackbar } = useSnackbar();
  const [isSelected, setIsSelected] = useState(false);
  const initialValues: FormikValues = {
    personNumber: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (customerId) {
        authenticationService()
          .startIdpAuthenticationSession({
            customerId,
            authType: AuthType.BankId,
            personNumber: emptyStringToUndefined(values.personNumber),
          })
          .then((authResponse: AuthenticationStartResponse) => {
            window.location.href = authResponse.redirectUrl;
          })
          .catch(() =>
            enqueueSnackbar(intl.formatMessage(messages.generalError), {
              variant: "error",
            })
          );
      }
    },
  });

  const handleOnClick = () => {
    setIsSelected(true);
    onClick();
  };

  const handleOnCancel = () => {
    setIsSelected(false);
    onCancel();
    formik.setFieldValue("personNumber", undefined);
  };

  useEffect(() => {
    // Remove all current errors if selected
    if (isSelected) {
      onError(undefined);
    }
  }, [isSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  if (process.env.REACT_APP_TEST_AUTHENTICATION && !isSelected) {
    return (
      <>
        {isCustomerAdminPortal ? (
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <Button
                onClick={handleOnClick}
                variant="contained"
                className={classes.loginBankIdButton}
              >
                {intl.formatMessage(messages.bankIdButton)}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <FeatureController
            requiredAnyOfFeatures={[
              CustomerFeatureType.BankIdLoginClient,
              CustomerFeatureType.BankIdLoginStaff,
              CustomerFeatureType.BankIdLoginExternal,
            ]}
          >
            <Grid container className={classes.container}>
              <Grid item xs={12}>
                <Button
                  onClick={handleOnClick}
                  variant="contained"
                  className={classes.loginBankIdButton}
                >
                  {intl.formatMessage(messages.bankIdButton)}
                </Button>
              </Grid>
            </Grid>
          </FeatureController>
        )}
      </>
    );
  }

  if (process.env.REACT_APP_TEST_AUTHENTICATION && isSelected) {
    return (
      <Grid container justify="center">
        <Grid item md={8} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              className={classes.textField}
              id="personNumber"
              placeholder="YYYYMMDDNNNN"
              name="personNumber"
              label={intl.formatMessage(messages.personNumber)}
              value={formik.values.personNumber}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
            />
            <Button variant="contained" type="submit" color="primary">
              {intl.formatMessage(messages.submitButtonLabel)}
            </Button>
            <Button onClick={handleOnCancel} color="primary">
              {intl.formatMessage(messages.cancelButtonLabel)}
            </Button>
          </form>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.container}>
      {isCustomerAdminPortal ? (
        <Grid item xs={12}>
          <Button
            onClick={() => formik.handleSubmit()}
            variant="contained"
            className={classes.loginBankIdButton}
          >
            {intl.formatMessage(messages.bankIdButton)}
          </Button>
        </Grid>
      ) : (
        <FeatureController
          requiredAnyOfFeatures={[
            CustomerFeatureType.BankIdLoginClient,
            CustomerFeatureType.BankIdLoginStaff,
            CustomerFeatureType.BankIdLoginExternal,
          ]}
        >
          <Grid item xs={12}>
            <Button
              onClick={() => formik.handleSubmit()}
              variant="contained"
              className={classes.loginBankIdButton}
            >
              {intl.formatMessage(messages.bankIdButton)}
            </Button>
          </Grid>
        </FeatureController>
      )}
    </Grid>
  );
};

export default BankIdLogin;
