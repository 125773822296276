import React from "react";
import { FormikProps } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { Grid } from "@material-ui/core";
import { IntlShape, useIntl } from "react-intl";
import { messages } from "./UpdateUsernameFields.messages";
import { messages as errorMessages } from "./Components/Validation/Validation.messages";
import TextField from "../TextField/TextField";
import PasswordField from "../PasswordField/PasswordField";

export interface UpdateUsernameFormikValues {
  newUsername: string;
  password: string;
}

interface Props {
  formik: FormikProps<UpdateUsernameFormikValues>;
}

const getErrorMessage = (intl: IntlShape, error?: any) => {
  if (!error || !errorMessages[error]) return "";
  return intl.formatMessage(errorMessages[error]);
};

const UpdateUsernameFields = (props: Props) => {
  const intl = useIntl();
  const { formik } = props;
  const { errors } = formik;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="newUsername"
          formik={formik}
          label={intl.formatMessage(messages.usernameLabel)}
          errorMsg={getErrorMessage(intl, errors.newUsername?.toString())}
        />
      </Grid>
      <Grid item xs={12}>
        <PasswordField
          id="password"
          formik={formik}
          label={intl.formatMessage(messages.passwordLabel)}
          errorMsg={getErrorMessage(intl, errors.password?.toString())}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateUsernameFields;
