import React from "react";
import { Button, ListItem, ListItemSecondaryAction } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useIntl } from "react-intl";
import { displayUser } from "../../../../../Utils/User";
import { User } from "../../../../../Models/User";
import { useStyles } from "./RenderOption.style";
import { UserRoleType } from "../../../../../generated/user-api";
import { messages } from "./RenderOption.messages";
import { useCustomerFeatures } from "../../../../../Providers/CustomerProvider/CustomerProvider";
import { hasFeatureEnabled } from "../../../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../../generated/customersettings-api";

export interface Option extends User {
  isNewUser?: boolean;
}

interface Props {
  option: Option;
  onCreateUser: (personNumber: string) => void;
  role?: UserRoleType;
}

const RenderOption = (props: Props) => {
  const { option, role, onCreateUser } = props;
  const classes = useStyles();
  const intl = useIntl();
  const customerFeatures = useCustomerFeatures();
  const doFormatPersonNumber = hasFeatureEnabled(
    CustomerFeatureType.UsesPersonNumber,
    customerFeatures
  );

  if (role && option.isNewUser) {
    return (
      <ListItem
        key={option.personNumber}
        className={classes.listItem}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ListItemSecondaryAction>
          <Button
            color="secondary"
            variant="outlined"
            aria-label={intl.formatMessage(messages.buttonLabel)}
            startIcon={<AddIcon />}
            onClick={() => onCreateUser(option?.personNumber || "")}
          >
            {intl.formatMessage(messages.buttonLabel)}
          </Button>
        </ListItemSecondaryAction>
        {option.personNumber}
      </ListItem>
    );
  }

  return (
    <ListItem key={option.id} className={classes.listItem}>
      {displayUser(doFormatPersonNumber, option)}
    </ListItem>
  );
};

export default RenderOption;
