/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerStatusType,
    CustomerStatusTypeFromJSON,
    CustomerStatusTypeFromJSONTyped,
    CustomerStatusTypeToJSON,
    DefaultLocale,
    DefaultLocaleFromJSON,
    DefaultLocaleFromJSONTyped,
    DefaultLocaleToJSON,
    Market,
    MarketFromJSON,
    MarketFromJSONTyped,
    MarketToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerResponse
 */
export interface CustomerResponse {
    /**
     * UUID of customer
     * @type {string}
     * @memberof CustomerResponse
     */
    id: string;
    /**
     * The full name of the customer
     * @type {string}
     * @memberof CustomerResponse
     */
    customerName: string;
    /**
     * Organization number of customer
     * @type {string}
     * @memberof CustomerResponse
     */
    organizationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponse
     */
    domain: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerResponse
     */
    isCustomDomain: boolean;
    /**
     * 
     * @type {Market}
     * @memberof CustomerResponse
     */
    market: Market;
    /**
     * 
     * @type {DefaultLocale}
     * @memberof CustomerResponse
     */
    defaultLocale: DefaultLocale;
    /**
     * 
     * @type {CustomerStatusType}
     * @memberof CustomerResponse
     */
    status: CustomerStatusType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerResponse
     */
    isAdminPortal: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CustomerResponse
     */
    created: Date;
}

export function CustomerResponseFromJSON(json: any): CustomerResponse {
    return CustomerResponseFromJSONTyped(json, false);
}

export function CustomerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerName': json['customerName'],
        'organizationNumber': json['organizationNumber'],
        'domain': json['domain'],
        'isCustomDomain': json['isCustomDomain'],
        'market': MarketFromJSON(json['market']),
        'defaultLocale': DefaultLocaleFromJSON(json['defaultLocale']),
        'status': CustomerStatusTypeFromJSON(json['status']),
        'isAdminPortal': json['isAdminPortal'],
        'created': (new Date(json['created'])),
    };
}

export function CustomerResponseToJSON(value?: CustomerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerName': value.customerName,
        'organizationNumber': value.organizationNumber,
        'domain': value.domain,
        'isCustomDomain': value.isCustomDomain,
        'market': MarketToJSON(value.market),
        'defaultLocale': DefaultLocaleToJSON(value.defaultLocale),
        'status': CustomerStatusTypeToJSON(value.status),
        'isAdminPortal': value.isAdminPortal,
        'created': (value.created.toISOString().substr(0,10)),
    };
}


