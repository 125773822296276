/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerifyPersonNumberResponse
 */
export interface VerifyPersonNumberResponse {
    /**
     * UserId of verified person number
     * @type {string}
     * @memberof VerifyPersonNumberResponse
     */
    userId: string;
    /**
     * Verified PersonNumber.
     * @type {string}
     * @memberof VerifyPersonNumberResponse
     */
    personNumber: string;
}

export function VerifyPersonNumberResponseFromJSON(json: any): VerifyPersonNumberResponse {
    return VerifyPersonNumberResponseFromJSONTyped(json, false);
}

export function VerifyPersonNumberResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyPersonNumberResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'personNumber': json['personNumber'],
    };
}

export function VerifyPersonNumberResponseToJSON(value?: VerifyPersonNumberResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'personNumber': value.personNumber,
    };
}


