import { Market as MarketCustomer } from "../generated/customer-api";
import { Market } from "../generated/customersettings-api";

export const toShorthand = (market?: Market | MarketCustomer): string => {
  if (market === Market.Morocco.toString().toUpperCase()) {
    return "ma";
  }
  if (market === Market.Sweden.toString().toUpperCase()) {
    return "se";
  }
  if (market === Market.Finland.toString().toUpperCase()) {
    return "fi";
  }
  return "se";
};
