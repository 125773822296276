import { defineMessages } from "react-intl";

export const messages = defineMessages({
  newPasswordLabel: {
    id: "updatePasswordFields.newPassword.label",
    defaultMessage: "New password",
    description: "Label for field new password",
  },
  currentPasswordLabel: {
    id: "updatePasswordFields.currentPassword.label",
    defaultMessage: "Current password",
    description: "Label for field current password",
  },
});
