import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LockIcon from "@material-ui/icons/Lock";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useStyles } from "./PrivateNotesCard.style";
import { messages } from "./PrivateNotesCard.messages";
import PrivateNotesEditor from "./Components/PrivateNotesEditor/PrivateNotesEditor";
import FeatureController from "../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../generated/customersettings-api";
import { notesService } from "../../Providers/ServiceProvider/ServiceProvider";
import { NotesResponse } from "../../generated/notes-api";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  meetingId: string;
  basicLook?: boolean;
  readonly?: boolean;
  onLoaded?: () => void;
  onLoading?: () => void;
}

const PrivateNotesCard = (props: Props) => {
  const { meetingId, basicLook, onLoaded, onLoading, readonly } = props;
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const [notesId, setNotesId] = useState<string>();
  const [notes, setNotes] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const createNote = () => {
    if (customerId) {
      notesService()
        .createMeetingNotes({
          customerId,
          meetingId,
          notesRequest: { notes },
        })
        .then((res) => {
          setNotesId(res.id);
        })
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        );
    }
  };

  useEffect(() => {
    if (customerId) {
      if (onLoading) {
        onLoading();
      }
      notesService()
        .listNotesByMeetingForUser({
          customerId,
          meetingId,
        })
        .then((res) => {
          const note: NotesResponse | undefined = res.data?.pop();
          if (note === undefined) {
            createNote();
          } else {
            setNotes(note?.notes || "");
            setNotesId(note?.id);
          }
        })
        .finally(onLoaded);
    }
  }, [meetingId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FeatureController requiredFeature={CustomerFeatureType.InMeetingNotes}>
      <Accordion defaultExpanded elevation={basicLook ? 0 : 1}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="notes-content"
          id="notes-header"
          className={basicLook ? classes.basicLook : undefined}
        >
          <Typography
            className={classes.cardHeading}
            component="h2"
            variant="subtitle2"
          >
            {intl.formatMessage(messages.title).toUpperCase()}
            <LockIcon fontSize="inherit" className={classes.icon} />
            {intl.formatMessage(messages.helperText)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={basicLook ? classes.basicLook : undefined}>
          <Container disableGutters>
            <Typography gutterBottom variant="subtitle2">
              <Box fontStyle="italic">
                {intl.formatMessage(messages.content)}
              </Box>
            </Typography>
            {notesId && (
              <PrivateNotesEditor
                readOnly={readonly}
                meetingId={meetingId}
                notesId={notesId}
                initialValue={notes}
              />
            )}
          </Container>
        </AccordionDetails>
      </Accordion>
    </FeatureController>
  );
};

export default PrivateNotesCard;
