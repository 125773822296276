import { DefaultLocale as DefaultLocaleCustomer } from "../generated/customer-api";
import {
  DefaultLocale,
  DefaultLocale as DefaultLocaleCustomerSettings,
} from "../generated/customersettings-api";
import { UserLocaleType } from "../generated/user-api";

export const toShorthand = (
  locale: DefaultLocaleCustomer | DefaultLocaleCustomerSettings | UserLocaleType
): string => {
  if (locale === DefaultLocale.English.toString().toUpperCase()) {
    return "en";
  }
  if (locale === DefaultLocale.Swedish.toString().toUpperCase()) {
    return "sv";
  }
  if (locale === DefaultLocale.French.toString().toUpperCase()) {
    return "fr";
  }
  return "en";
};

export const toUserLocaleType = (locale: string): UserLocaleType => {
  if (locale === "en") {
    return UserLocaleType.English;
  }
  if (locale === "sv") {
    return UserLocaleType.Swedish;
  }
  if (locale === "fr") {
    return UserLocaleType.French;
  }
  return UserLocaleType.English;
};
