/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DefaultLocale,
    DefaultLocaleFromJSON,
    DefaultLocaleFromJSONTyped,
    DefaultLocaleToJSON,
    Market,
    MarketFromJSON,
    MarketFromJSONTyped,
    MarketToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerRequest
 */
export interface CustomerRequest {
    /**
     * The full name of the customer
     * @type {string}
     * @memberof CustomerRequest
     */
    customerName: string;
    /**
     * Organization number of customer
     * @type {string}
     * @memberof CustomerRequest
     */
    organizationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRequest
     */
    domain: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerRequest
     */
    isCustomDomain: boolean;
    /**
     * 
     * @type {Market}
     * @memberof CustomerRequest
     */
    market: Market;
    /**
     * 
     * @type {DefaultLocale}
     * @memberof CustomerRequest
     */
    defaultLocale: DefaultLocale;
}

export function CustomerRequestFromJSON(json: any): CustomerRequest {
    return CustomerRequestFromJSONTyped(json, false);
}

export function CustomerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerName': json['customerName'],
        'organizationNumber': json['organizationNumber'],
        'domain': json['domain'],
        'isCustomDomain': json['isCustomDomain'],
        'market': MarketFromJSON(json['market']),
        'defaultLocale': DefaultLocaleFromJSON(json['defaultLocale']),
    };
}

export function CustomerRequestToJSON(value?: CustomerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerName': value.customerName,
        'organizationNumber': value.organizationNumber,
        'domain': value.domain,
        'isCustomDomain': value.isCustomDomain,
        'market': MarketToJSON(value.market),
        'defaultLocale': DefaultLocaleToJSON(value.defaultLocale),
    };
}


