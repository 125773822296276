import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  usernameRequired: {
    id: "validation.updateUsername.usernameRequired",
    defaultMessage: "Username is required",
    description: "Text to display when user attempts to enter empty username",
  },
  usernameTooLong: {
    id: "validation.updateUsername.usernameTooLong",
    defaultMessage: "Username can not be longer than 150 characters",
    description:
      "Text to display when user attempts to enter a username that is longer than 150 characters",
  },
  usernameTooShort: {
    id: "validation.updateUsername.usernameTooShort",
    defaultMessage: "Username can not be shorter than 3 characters",
    description:
      "Text to display when user attempts to enter a username that is shorter than 3 characters",
  },
  passwordRequired: {
    id: "validation.updateUsername.passwordRequired",
    defaultMessage: "Password is required",
    description: "Text to display when user attempts to enter empty password",
  },
});
