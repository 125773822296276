import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useFormik } from "formik";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import {
  CreateUserRequest,
  UserResponse,
  UserRoleType,
} from "../../../generated/user-api";
import { messages } from "./CreateUser.messages";
import CreateUserValidationSchema from "../../UserForm/Components/Validation/CreateUserValidationSchema";
import UserFields from "../../UserForm/Components/UserFields/UserFields";
import AuthorityFields from "../../UserForm/Components/AuthorityFields/AuthorityFields";
import ButtonLoadingWrapper from "../ButtonLoadingWrapper/ButtonLoadingWrapper";
import { useCustomerCurrentUpdate } from "../../../Providers/RecentUpdatesProvider/RecentUpdatesProvider";
import { userService } from "../../../Providers/ServiceProvider/ServiceProvider";
import { User } from "../../../Models/User";
import { decorateUserWithPersonNumber } from "../../../Utils/Decorator";
import { emptyStringToUndefined, formatHSAID } from "../../../Utils/Format";
import { hasFeatureEnabled } from "../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../generated/customersettings-api";
import { useCustomerFeatures } from "../../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  onCreated?: (user: User) => void;
  isOpen: boolean;
  title: string;
  onClose: () => void;
  preFilledPersonNumber?: string;
  userRole: UserRoleType;
  customerId: string;
}

const mapUserObject = (user: CreateUserRequest) => {
  return {
    lastName: user.lastName,
    userRole: user.userRole,
    firstName: user.firstName,
    userAuthorities: user.userAuthorities,
    hsaId: formatHSAID(emptyStringToUndefined(user.hsaId)),
    email: emptyStringToUndefined(user.email),
    workTitle: emptyStringToUndefined(user.workTitle),
    personNumber: emptyStringToUndefined(user.personNumber?.replace("-", "")),
    mobilePhoneNumber: emptyStringToUndefined(user.mobilePhoneNumber),
    additionalInformation: emptyStringToUndefined(user.additionalInformation),
  } as CreateUserRequest;
};

const CreateUserDialog = (props: Props) => {
  const {
    isOpen,
    userRole,
    onClose,
    title,
    onCreated,
    preFilledPersonNumber,
    customerId,
  } = props;
  const intl = useIntl();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [isCreatingUserLoading, setIsCreatingUserLoading] = useState<boolean>(
    false
  );
  const [customerupdates, setCustomerupdates] = useCustomerCurrentUpdate();
  const customerFeatures = useCustomerFeatures();
  const initialValues: CreateUserRequest = {
    personNumber: hasFeatureEnabled(
      CustomerFeatureType.UsesPersonNumber,
      customerFeatures
    )
      ? preFilledPersonNumber || ""
      : "",
    firstName: "",
    lastName: "",
    userRole,
    userAuthorities: [],
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: CreateUserValidationSchema(
      hasFeatureEnabled(CustomerFeatureType.UsesPersonNumber, customerFeatures)
    ),
    onSubmit: (values) => {
      if (customerId) {
        setIsCreatingUserLoading(true);
        userService()
          .createUser({
            customerId,
            body: mapUserObject(values),
          })
          .then((user: User) => {
            const arrayCopy: UserResponse[] = customerupdates.userResponse;
            arrayCopy.push(user);
            setCustomerupdates({ userResponse: arrayCopy });
            onCreated && onCreated(decorateUserWithPersonNumber(user, mapUserObject(values).personNumber));// eslint-disable-line
          })
          .then(() => {
            formik.resetForm();
            enqueueSnackbar(intl.formatMessage(messages.success), {
              variant: "success",
            });
            onClose();
          })
          .catch((error) => {
            if (error.status === 409) {
              let errorMessage = "";
              switch (userRole) {
                case UserRoleType.Client:
                  errorMessage = intl.formatMessage(
                    messages.duplicatePatientError
                  );
                  break;
                case UserRoleType.External:
                  errorMessage = intl.formatMessage(
                    messages.duplicateExternalError
                  );
                  break;
                case UserRoleType.Staff:
                  errorMessage = intl.formatMessage(
                    messages.duplicateStaffError
                  );
                  break;
                default:
                  errorMessage = intl.formatMessage(messages.generalError);
                  break;
              }
              enqueueSnackbar(errorMessage, {
                variant: "error",
              });
            } else {
              enqueueSnackbar(intl.formatMessage(messages.generalError), {
                variant: "error",
              });
            }
          })
          .finally(() => setIsCreatingUserLoading(false));
      }
    },
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <UserFields formik={formik} userRole={userRole} hideUsername />
          <AuthorityFields formik={formik} userRole={userRole} />
        </DialogContent>
        <DialogActions>
          <ButtonLoadingWrapper isLoading={isCreatingUserLoading}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={
                (formik.values.userAuthorities?.length === 0 &&
                  userRole !== UserRoleType.PlatformAdmin) ||
                isCreatingUserLoading
              }
            >
              {intl.formatMessage(messages.submitButtonLabel)}
            </Button>
          </ButtonLoadingWrapper>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage(messages.cancelButtonLabel)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateUserDialog;
