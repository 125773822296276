/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateUserStatusRequest
 */
export interface UpdateUserStatusRequest {
    /**
     * 
     * @type {UserStatusType}
     * @memberof UpdateUserStatusRequest
     */
    newStatus: UserStatusType;
}

export function UpdateUserStatusRequestFromJSON(json: any): UpdateUserStatusRequest {
    return UpdateUserStatusRequestFromJSONTyped(json, false);
}

export function UpdateUserStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newStatus': UserStatusTypeFromJSON(json['newStatus']),
    };
}

export function UpdateUserStatusRequestToJSON(value?: UpdateUserStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newStatus': UserStatusTypeToJSON(value.newStatus),
    };
}


