import * as Yup from "yup";

// text string is used as a key to fetch intl message
const CustomerFormValidationSchema = () => {
  return Yup.object().shape({
    customerName: Yup.string()
      .required("customerNameRequired")
      .max(50, "customerNameTooLong"),
    domain: Yup.string().max(50, "domainTooLong").required("domainRequired"),
    organizationNumber: Yup.string()
      .max(20, "organizationNumberTooLong")
      .notRequired(),
  });
};

export default CustomerFormValidationSchema;
